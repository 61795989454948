.form-19CNe {
	border-radius: 4px;
}

.form-19CNe,
.form-19CNe:hover {
	box-shadow: none;
}

.disabilities-2EjWa {
	background: var(--brand-1-5);
}

.checkboxes-3WKhY {
	grid-gap: 10px;
	gap: 10px;
}

.checkboxes-3WKhY label {
	grid-gap: 12px;
	gap: 12px;
	margin-left: 0;
}

.checkboxes-3WKhY label > span:last-child {
	line-height: 1.2;
}

.loyalty__desc-2UyKf a {
	color: var(--brand-3-0);
}

.toggle_active-3CtPI:after {
	background: var(--brand-3-0);
}

.group_container-1-_Xa:last-child {
	margin-bottom: 5px;
}

.group_container-1-_Xa:not(:last-child) {
	margin-bottom: 32px;
}

@media screen and (max-width: 600px) {
	div.header-1DAJ7.header__filled-2Ru30 .header__number-k58N7 {
		background: var(--success-icon);
	}

	.disabilities-2EjWa {
		margin-top: 0;
	}
}
