.container-k61f5 {
	height: 70px;
	margin-bottom: 28px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: var(--white);
}

.wrapper-3FWIA {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	max-width: 1100px;
	margin: 0 auto;
}

.title-3HPHn {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1;
}

@media (max-width: 1024px) {
	.container-k61f5 {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.container-k61f5 {
		height: auto;
		padding: 14px 20px 10px;
		margin-bottom: 12px;
	}

	.wrapper-3FWIA {
		flex-direction: column;
		align-items: flex-start;
		grid-gap: 8px;
		gap: 8px;
	}

	.title-3HPHn {
		font-size: 22px;
	}
}
