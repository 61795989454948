.container-5p5c6 {
	background-color: transparent;
}

.multiCity__multiCity-3oW2y {
	display: none;
}

.datepicker__dates--vWkG,
.segment__input-DVcE9,
.passengers__selector-2k63j,
.currency__selector-Avv79,
.startSearch__startSearch-1r759,
.startSearch__startSearch-1r759.startSearch__startSearchIconMode-1EffY button {
	height: 80px;
}

.currency__selector-Avv79,
.datepicker__dates--vWkG,
.value__wrapper-_t7b4 .value-3fiII,
.passengers__selector-2k63j {
	font-weight: 400;
}

.location__switcher-2yTRk svg {
	display: none;
}

.startSearch__startSearch-1r759 button svg {
	width: 21px;
	height: 21px;
}

.startSearch__startSearch-1r759 button:hover {
	background-color: var(--brand-1-0);
}

.datepicker__dates--vWkG .datepicker__dates__icon-2gDwp {
	color: var(--brand-1-0);
}

.datepicker__dates__back-3zpOy {
	line-height: 80px;
}

.controls-1m6MB {
	top: 81px;
}

.segment__cell_date-3Qt91 {
	height: 80px;
}

.segment__cell_location-3YG2D.segment__cell_location_arrival-1Zecr .value__wrapper-_t7b4 {
	padding-right: 10px;
}

.location__switcher-2yTRk {
	right: 0;

	width: 64px;
	height: 80px;

	background-color: var(--white);
}

.location__switcher-2yTRk:after {
	content: '';

	width: 14px;
	height: 22px;

	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDE0IDIyIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMzAyNSA0Ljg1MzdMNy4xNTI4MyAwLjcwNzM5OEw3Ljg1OTY0IDBMMTIuODYzOCA1TDEzLjIxNzcgNS4zNTM3TDEyLjg2MzggNS43MDc0TDcuODU5NjQgMTAuNzA3NEw3LjE1MjgzIDEwTDExLjMwMjUgNS44NTM3SDBWNC44NTM3SDExLjMwMjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIuNTA1ODEgMTYuODQ0N0w2LjY1NTUyIDIwLjk5MUw1Ljk0ODcxIDIxLjY5ODRMMC45NDQ1OTMgMTYuNjk4NEwwLjU5MDYwMiAxNi4zNDQ3TDAuOTQ0NTkzIDE1Ljk5MUw1Ljk0ODcxIDEwLjk5MUw2LjY1NTUyIDExLjY5ODRMMi41MDU4MSAxNS44NDQ3TDEzLjgwODQgMTUuODQ0N0wxMy44MDg0IDE2Ljg0NDdMMi41MDU4MSAxNi44NDQ3WiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=) center center no-repeat;
}

.location__switcher-2yTRk.switcher_alternative-3IZYK {
	border-radius: 0;

	background-color: var(--white);
}

.location__switcher-2yTRk.switcher_alternative-3IZYK,
.datepicker__dates--vWkG,
.passengers__selector-2k63j,
.currency__selector-Avv79,
.segment__input-DVcE9 {
	border: 1px solid transparent;

	transition: 0.2s ease-out;
}

.location__switcher-2yTRk.switcher_alternative-3IZYK:hover,
.datepicker__dates--vWkG:hover,
.passengers__selector-2k63j:hover,
.currency__selector-Avv79:hover,
.segment__input-DVcE9:hover {
	border-color: var(--brand-1-0);
}

@media screen and (min-width: 600px) {
	.segment__cell-25ctK:first-child .segment__input-DVcE9 {
		width: calc(100% - 64px);
		padding-right: 0;
		margin-left: -1px;
	}

	.segment__cell_location-3YG2D:not(.segment__cell_location_arrival-1Zecr) .value__wrapper-_t7b4 {
		padding-right: 0;
		width: calc(100% - 64px);
	}

	.segment__wrapper-173nP,
	.passengers__passengers-2onwg,
	.segment__cell_location-3YG2D {
		border-right-color: transparent;
	}
}

@media (min-width: 1024px) {
	.segment__cell_location-3YG2D:not(.segment__cell_location_arrival-1Zecr) {
		flex-basis: 280px;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.segment__cell_date-3Qt91 {
		margin-top: 1px;
	}

	.passengers__passengers-2onwg {
		width: calc(50% - 35px);
	}

	.segment__wrapper-173nP:not(.withMultiCity-1dopG) .segment__cell-25ctK:last-child {
		border-right-color: transparent;
	}
}

@media screen and (max-width: 600px) {
	.segment__alternative-333D8 .segment__cell_location-3YG2D .segment__input-DVcE9,
	.segment__alternative-333D8 .segment__cell_date-3Qt91,
	.passengers__alternative-3eq0M .passengers__selector-2k63j {
		height: 80px;
	}

	.segment__departureInputWrapper-VOd75 .autocomplete-1c2Xb:before {
		content: none;
	}

	.segment__cell_location-3YG2D:not(.segment__cell_location_arrival-1Zecr) {
		flex-basis: 50%;
	}

	.segment__cell_location-3YG2D:not(.segment__cell_location_arrival-1Zecr) .value__wrapper-_t7b4 {
		padding-right: 0;
	}

	.segment__cell_location-3YG2D.segment__cell_location_arrival-1Zecr .value__wrapper-_t7b4 {
		padding-right: 0;
	}

	.location__switcher-2yTRk.switcher_alternative-3IZYK {
		border-color: var(--line-separator);
	}
}
