.airplane__info-1xfY2 {
	padding-bottom: 12px;
}

.airplane__name-3SqFH {
	color: var(--text-link);
	font-weight: 400;
	font-size: 13px;
}

.airplane__name-3SqFH > a {
	color: var(--text-link);
	text-decoration: none;
}

.airplane__details-1ynb8 {
	height: 44px;
}
