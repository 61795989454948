.header-1dEqC {
	min-height: 30px;
	margin-bottom: 20px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.4;
}

.header-1dEqC span {
	color: var(--success-icon);
}
