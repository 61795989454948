

.wrapper-1OTT6 {
	color: var(--text-base);
}

.title-1cO71 {
	font-size: 20px;
}

.info-1x9lT {
	color: var(--text-mid);
}

.number-2syV5 {
	background-color: var(--success-icon);
}

.close-3k9H4 {
	top: 8px;
	right: 8px;

	background-color: var(--bg-fill);
}

.close-3k9H4:before,
.close-3k9H4:after {
	background-color: var(--text-pale);
}
