

.close-3zOZu {
	background: var(--bg-fill);
}

.footer-2z3MJ button:first-child {
	border: 1px solid var(--brand-3-0);
	border-radius: 4px;

	color: var(--text-light);

	background: var(--bg-fill);
}
