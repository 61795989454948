

button.rules__button-3lXuU {
	border-radius: 6px;

	background: var(--brand-3-0);
}

button.rules__button-3lXuU:hover {
	background: var(--brand-3--1);
}

.extrasBanner-2rrCf .content-2loaK {
	background: none;
}

.servicePrice-qkE_W {
	color: #262626;
	font-weight: 400;
}
