.controls__wrapper-1iyMz {
	align-items: center;
}

@media screen and (max-width: 600px) {
	.leg-2dseS {
		border-color: var(--line-separator);

		color: var(--text-base);
		font-weight: 500;
	}
}
