

.dayWrapper-3ITLw {
	padding: 0;
}

.dayWrapper-3ITLw:not(:last-child) {
	border-right: 1px solid var(--line-separator);
}

.dayWrapper-3ITLw:first-child .day-2pr2n {
	border-radius: 4px 0 0 4px;
}

.dayWrapper-3ITLw:last-child .day-2pr2n {
	border-radius: 0 4px 4px 0;
}
