.breadcrumbs__item-2Aobt {
	height: 40px;

	color: var(--base-15);
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;

	cursor: pointer;
	background: var(--base90);
}
