.meal-1Po0s {
	height: 346px;
}

.meal-1Po0s:hover {
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.meal-1Po0s:hover:after {
	border-color: var(--brand-3-0);
}

.price-1O91t {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);

	background: var(--white);
}

.details__name-2T4fQ,
.details__price-ZCrh3,
.details__totalText-3Lq5D,
.details__total-1FpC0 {
	font-size: 22px;
	color: var(--text-base);
}

.name-2_dmv,
.details__name-2T4fQ {
	text-transform: none;
	text-transform: initial;
}

.count-1UMnd {
	background-color: var(--success-icon);
}

.details__trigger-3dPga {
	color: var(--text-mid);
	font-weight: 700;
}

.details__counter-3FqJ4 {
	border-bottom: 1px dashed var(--line-separator);
}

.details__clear-3eUKL {
	border-right-color: var(--line-separator);
}

.details__addForAll-3AysC,
.details__clear-3eUKL {
	color: var(--blue);
	font-weight: 500;
	font-size: 14px;
}

button.details__button_empty-GJ5Uy {
	background: var(--brand-3-0);
	opacity: 1;
}

button.details__button_empty-GJ5Uy:hover {
	background: var(--brand-3-1);
}

.details__selector-3vTtR {
	border-radius: 0 0 var(--baggage-additional-wrapped-border-radius) var(--baggage-additional-wrapped-border-radius);
}

.details_passenger-3nKF6 {
	color: var(--text-base);
}

.details_passenger__index-i2oAW {
	color: var(--light-text);
}

.header-3TG60 {
	z-index: unset;
}

@media screen and (max-width: 600px) {
	.details__footerPrice-2VuJ0 {
		display: block;
	}

	.details__clear-3eUKL {
		margin-left: 0;
		padding-left: 0;
		margin-top: 5px;

		color: var(--brand-1-0);
	}
}
