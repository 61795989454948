

div.compareFares-1lTyF {
	border-radius: 20px;
}

.groupTitle-3F7P4 {
	border-color: var(--line-separator);
}

.tableRow-2kD-m:nth-of-type(even) {
	background: var(--bg-fill);
}

.slider-2UE1d .slick-next,
.slider-2UE1d .slick-prev,
.slider-2UE1d .slick-next:focus,
.slider-2UE1d .slick-prev:focus {
	background: var(--white);
}

.slider-2UE1d .slick-next svg *,
.slider-2UE1d .slick-prev svg * {
	fill: var(--text-mid);
}
