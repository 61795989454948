.toolbar-tq2rq {
	box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
}

button.button-3yw0L,
div.button-3yw0L {
	background-color: var(--bg-accent);
}

button.button-3yw0L:hover {
	background-color: var(--brand-3--1);
}

.price-1Qdvh {
	position: relative;

	padding-right: 18px;

	color: var(--text-base);
	font-weight: 700;
}

.price-1Qdvh:after {
	content: '';

	position: absolute;
	top: 0;
	right: 0;

	width: 17px;
	height: 17px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk5MzMzIDEuODMzOThDNC4zMTMzMyAxLjgzMzk4IDEuMzMzMzMgNC44MjA2NSAxLjMzMzMzIDguNTAwNjVDMS4zMzMzMyAxMi4xODA3IDQuMzEzMzMgMTUuMTY3MyA3Ljk5MzMzIDE1LjE2NzNDMTEuNjggMTUuMTY3MyAxNC42NjY3IDEyLjE4MDcgMTQuNjY2NyA4LjUwMDY1QzE0LjY2NjcgNC44MjA2NSAxMS42OCAxLjgzMzk4IDcuOTkzMzMgMS44MzM5OFpNOCAxMy44MzRDNS4wNTMzMyAxMy44MzQgMi42NjY2NyAxMS40NDczIDIuNjY2NjcgOC41MDA2NUMyLjY2NjY3IDUuNTUzOTggNS4wNTMzMyAzLjE2NzMyIDggMy4xNjczMkMxMC45NDY3IDMuMTY3MzIgMTMuMzMzMyA1LjU1Mzk4IDEzLjMzMzMgOC41MDA2NUMxMy4zMzMzIDExLjQ0NzMgMTAuOTQ2NyAxMy44MzQgOCAxMy44MzRaTTcuMzMzMzMgNS4xNjczMkg4LjMzMzMzVjguNjY3MzJMMTEuMzMzMyAxMC40NDczTDEwLjgzMzMgMTEuMjY3M0w3LjMzMzMzIDkuMTY3MzJWNS4xNjczMloiIGZpbGw9IiNFMDIyMjIiLz4KPC9zdmc+Cg==) no-repeat center / contain;
}

button.backButton-1S0p3 {
	font-weight: 600;

	background-color: var(--brand-1-5);
}

button.backButton-1S0p3:hover,
.backButton__wrapper-339zR:hover > .backButton-1S0p3 {
	color: var(--text-accent);

	background-color: var(--bg-fill);
}

.backButton__wrapper-339zR:hover > span {
	color: var(--text-mid);
}

@media screen and (max-width: 600px) {
	.price-1Qdvh:after {
		display: none;
	}
}
