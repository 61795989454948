.container-X4dmT {
	position: relative;

	display: flex;
	align-items: center;
}

button.selector-lp9fo {
	color: var(--white);
	font-size: 16px;
	font-weight: 500;
}

.icon-9nvcr {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 8px;
}

.weekpicker-1KB-b {
	z-index: 10;

	position: absolute;
	top: 100%;
	left: 0;

	transform: translateY(10px);
}

@media screen and (max-width: 600px) {
	.container-X4dmT {
		width: 100%;
	}

	.weekpicker-1KB-b {
		z-index: 0;

		position: relative;
		top: 0;

		padding-top: 45px;

		transform: none;
	}

	div.scrollBody-34UPu div.paper-2KJ_4 {
		border-radius: 0;
	}
}
