.wrapper-2dBty {
	flex-direction: column;
	align-items: inherit;

	border-bottom: 1px dashed var(--line-dot-line);

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.wrapper-2dBty:not(:last-of-type) {
	border-color: var(--line-dot-line);
}

.wrapper-2dBty:only-child {
	border-bottom: none;
}

.passenger-3pZ4g {
	margin-bottom: 16px;
}

.passengerIcon-3crKx {
	display: none;
}

.passengerName-1Qhpl {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.passengerInfo-3HbUD {
	align-items: start;
	flex-direction: column;

	grid-gap: 6px;

	gap: 6px;
}

.passengerInfo__item-3EFVr {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.passengerInfo__item-3EFVr:not(:last-child) {
	display: block;

	margin-right: 0;
	padding-right: 0;

	border-right: none;
}

.passengerInfo__item-3EFVr:last-child {
	grid-gap: 8px;
	gap: 8px;
}

.passengerInfo__field-1cyhJ {
	display: inline-block;
}

.passengerInfo__field-1cyhJ br {
	display: none;
}

.passengerInfo__field-1cyhJ:not(:last-child) {
	margin: 0;
}

.passengerInfo__item-3EFVr:not(:last-child) .passengerInfo__field-1cyhJ:not(:last-child):after {
	content: ',';

	margin-right: 4px;
}

.passengerInfo__item-3EFVr:first-child .passengerInfo__field-1cyhJ span:first-child {
	display: none;
}

.loyalty__link-3q0HP {
	text-decoration: none;
}

@media screen and (max-width: 600px) {
	.wrapper-2dBty:first-of-type {
		border-radius: 0;
	}

	.wrapper-2dBty:not(:last-of-type) {
		border-bottom: 1px dashed var(--line-dot-line);
	}

	.passengerInfo__item-3EFVr {
		flex-grow: unset;
		flex-basis: auto;
	}

	.passengerInfo__field-1cyhJ {
		width: auto;

		margin: 0;
	}

	.passengerInfo__field-1cyhJ > span:first-child {
		margin-right: 4px;
	}

	.passengerInfo__field-1cyhJ > span:last-child {
		margin: 0;
	}

	.passengerInfo__field-1cyhJ > span:first-child,
	.passengerInfo__field-1cyhJ > span:last-child {
		display: inline-block;

		color: var(--text-base);
	}
}
