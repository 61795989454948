

div.segmentsTabs-kxP17 {
	grid-gap: 20px;
	gap: 20px;
}

div.segmentsTabs-kxP17 button.segmentTabsTab-1hOv1:not(.segmentTabsTab__active-1n0TW) {
	border: none;
	border-bottom: 3px solid transparent;
}

div.segmentsTabs-kxP17 button.segmentTabsTab-1hOv1:not(.segmentTabsTab__active-1n0TW) span {
	color: var(--text-light);
}
