

.time-1LHzV,
.city-1GLh- {
	color: var(--text-base);
}

.date-1HLpE {
	color: var(--text-light);
	font-weight: 400;
}

.terminal-H18WS {
	color: var(--brand-1-0);
}

.date_icon-1w4S3 {
	color: var(--text-pale);
}

.isMobile-5vr21 .time_iata-1E9Go {
	color: var(--text-light);
}
