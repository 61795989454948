

.header-6flEB {
	color: var(--text-mid);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.option-1NPvn,
.option-1NPvn.small-3h3Yv {
	border-color: var(--brand-1-5);
	border-radius: 4px;
}

.option-1NPvn.paid-3WQxg {
	border: 1px solid var(--line-separator);
}

.option-1NPvn.small-3h3Yv {
	background: var(--brand-1-0);
}

.option-1NPvn.small-3h3Yv.paid-3WQxg {
	border: none;

	background: var(--seat-unavailable);
}

.option-1NPvn.not_available-3usmi {
	color: var(--text-pale);

	opacity: 1;
	background: var(--seat-unavailable);
}

.upgradeOffer-2qp03 {
	display: none;
}
