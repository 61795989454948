.route-2soUH {
	font-size: 18px;
	line-height: 1.3;
}

.route__icon-CAPNx:before {
	content: '\2013';
}

.route__icon-CAPNx svg {
	display: none;
}

span.passengers-2uLo5:before {
	top: 0;

	transform: none;
}

.passengers-2uLo5 {
	position: relative;

	margin-left: 12px;
	padding-left: 16px;

	border-left: none;

	font-size: 18px;
	line-height: 1.3;
	font-weight: 400;
}

.passengers-2uLo5:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 0;

	width: 4px;
	height: 4px;

	border-radius: 50%;

	background: var(--quickSearchForm-summary-trigger-border-color);

	transform: translateY(-50%);
}

@media screen and (max-width: 600px) {
	.route-2soUH {
		color: var(--quickSearchForm-summary-route-color);
		font-size: 16px;
	}
}
