.group__groupLabel-170up {
	color: var(--brand-1-0);
	font-size: 13px;
	font-weight: 700;

	white-space: nowrap;
}

.option__code-3ycMn {
	width: 44px;

	font-size: 16px;
}

button.dropdown__allDirectionsButton-3rEHk,
button.dropdown__allDirectionsButton-3rEHk:hover {
	border-radius: 4px;

	background-color: var(--brand-1-0);
}

.option__option-2C3e6 {
	height: 45px;
	padding: 0 16px;
}

.option__info-1roJd {
	line-height: 45px;
}

.directionsDialog__dialog-1XCbx {
	background-color: var(--bg-fill);
}

.directionsDialog__label-1KDXT {
	color: var(--brand-1--2);
	font-size: 22px;
	font-weight: 700;
}

.directionsDialog__country-3Wr_O {
	font-size: 16px;
	line-height: 20px;
}

.directionsDialog__countryActive-25LUS {
	border-radius: 4px;
}

.directionsDialog__city-2mVrB {
	border-bottom: none;

	font-size: 16px;
}

.option__option-2C3e6:hover,
.directionsDialog__city-2mVrB:hover {
	background-color: var(--brand-1-5);
}

.directionsDialog__iata-1u-p5 {
	font-size: 16px;
}

.dropdown__options-1zKTU {
	min-width: 230px;
}

@media screen and (max-width: 600px) {
	.mobileDialog__hint-3q5IA {
		font-size: 14px;
	}
}
