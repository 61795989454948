button.passengerStyles__increase-2gYd5,
button.passengerStyles__decrease-18Y4G {
	background: var(--brand-1-5);
}

button.passengerStyles__increase-2gYd5:not(:disabled),
button.passengerStyles__decrease-18Y4G:not(:disabled) {
	color: var(--brand-1-0);
}

button.passengerStyles__increase-2gYd5:hover,
button.passengerStyles__decrease-18Y4G:hover {
	color: var(--white);

	background: var(--brand-1-0);
}

.passengerStyles__counter-2mJNm {
	color: var(--brand-1-0);
}

.passengerStyles__passengerType-1Va57 {
	font-size: 16px;
	color: var(--text-base);
}

.passengerStyles__age-2Ut3i {
	font-weight: 400;
	color: var(--text-light);
}

@media screen and (max-width: 600px) {
	.passengerStyles__passengerType-1Va57 {
		font-size: 18px;
	}

	button.passengerStyles__increase-2gYd5:not(:disabled) svg,
	button.passengerStyles__decrease-18Y4G:not(:disabled) svg {
		fill: var(--brand-1-0);
	}

	button.passengerStyles__increase-2gYd5:hover,
	button.passengerStyles__decrease-18Y4G:hover {
		background: var(--brand-1-5);
	}
}
