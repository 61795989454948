

.root-3PptW {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.control-p6Ziw {
	min-width: 0;
	height: 32px;
	margin-left: 12px;
	padding: 0 22px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1;
}

button.control-p6Ziw.control__edit-3FBPP {
	color: var(--base);

	background: var(--gray05);
}

button.control-p6Ziw.control__edit-3FBPP:hover {
	opacity: 0.8;
}

.price-25vRr {
	font-weight: 700;
	font-size: 18px;
	line-height: 1;
	color: var(--base);
}

.price__selected-2eqQH {
	color: var(--gray80);
}
