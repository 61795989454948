

a.rules-1CfdE {
	display: flex;
	justify-content: center;
	align-items: center;

	min-height: 42px;

	margin-bottom: 12px;
	padding-top: 2px;

	border-radius: 4px;

	color: var(--white);
	font-weight: 700;
	font-size: 14px;
	line-height: 1;

	background: var(--brand-3-0);

	transition: background 0.15s;
}

a.rules-1CfdE:hover {
	background: var(--brand-3-1);
}

a.rules-1CfdE svg {
	width: 22px;
	height: 22px;

	margin-top: -2px;
	margin-right: 6px;
}
