

.timeInRoute-7jttr {
	top: 30px;

	padding: 0 6px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 16px;
}

.flightNumber-4JDCW {
	color: var(--brand-1-0);
}

.routeArrow__container-2QObF {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 120px;
	max-width: 150px;
	margin: 0 12px;
	justify-content: space-between;
	align-items: center;

	border: none;
}
