

.container-3FGc2.oldVersion-3hrwP {
	background: var(--brand-2-0);
}

button.change-2QlCh {
	background: var(--brand-2-0);
}

button.change-2QlCh:hover {
	background: var(--brand-2-1);
}

div.paper-1M84C {
	background: var(--bg-fill);
}

div.paper-1M84C .wrapper-3whvy {
	margin-top: 0;

	background: none;
}

div.paper-1M84C .header-1tp69 {
	display: none;
}

div.paper-1M84C .insurancesList-17Gxw {
	margin-top: 0;
}

div.paper-1M84C .insuranceProduct-oQmOs {
	background: var(--bg-white);
}

.oldVersion-3hrwP .footer-2aUsF:before {
	background-position: 50% 100%;
	background-size: 90%;
}
