

.wrapper-HezXU {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 25px 20px 20px;
	width: 100%;

	border-radius: 12px;

	background-color: var(--white);
}

.header-2PGR3 {
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	padding-bottom: 20px;

	border-bottom: 1px dashed #d9d9d9;
}

.header-2PGR3.selected-3KPpZ {
	padding-bottom: 0;

	border-bottom: none;
}

.title-2Qk0U {
	margin-bottom: 4px;

	color: var(--text);
	font-weight: 700;
	font-size: 18px;
	line-height: 1.3;
}

.title-2Qk0U a {
	color: var(--brand-3-0);
}

.icon-27HFb {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	margin-right: 20px;

	flex-shrink: 0;

	border-radius: 50%;

	color: var(--base);

	background-color: #f6f6f6;
}

.icon-27HFb > svg {
	width: 60%;
	height: 60%;
}

.info-PjlC6 {
	display: flex;
	flex-direction: column;
}

.description-3ReJO {
	font-size: 16px;
	line-height: 1.3;
	color: var(--gray50);
}

.footer-oj0Sp {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.price-1efn3 {
	margin-right: 12px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

button.button-2PGA_ {
	min-width: 70px;

	border: 1px solid #add3ff;

	color: var(--base);

	background-color: #eaf4ff;
}

button.button-2PGA_:hover {
	border: 1px solid var(--base);

	color: var(--white);

	background-color: var(--base);
}

button.button_edit-aR-AO {
	min-width: 70px;
	min-height: 40px;

	border-color: #f6f6f6;

	color: var(--text);

	background-color: #f6f6f6;
}

button.button_edit-aR-AO:hover {
	border-color: var(--gray05);

	color: var(--text);

	background-color: var(--gray05);
}
