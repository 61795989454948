

button.button-2OAVn {
	color: var(--blue);

	background-color: var(--blue-light);
}

button.button_disabled-3Ybax {
	color: var(--line-dot-line);

	background-color: var(--blue-light);
}

button.button-2OAVn:not(.button_disabled-3Ybax):hover {
	color: var(--bg-white);

	background-color: var(--blue);
}
