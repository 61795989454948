

.modal__content-21JH7 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 28px;
}

.modal__content_insideSlideBottom-QorO1 {
	padding: 0;
}

.modal__text-2nQLm {
	margin: 22px 0;

	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	text-align: center;
}

button.modal__button-3ylvn {
	min-width: 200px;
}

.modal__button-3ylvn:first-of-type {
	margin-bottom: 12px;
}

button.modal__button_decline-2-C8-,
button.modal__button_decline-2-C8-:hover {
	color: var(--common-gray);

	background-color: #ededed;
}
