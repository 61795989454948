

.flight-1na8M {
	border-radius: 4px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

button.price-13Eel {
	background: var(--brand-3-0);
}

button.price-13Eel:hover {
	background: var(--brand-3--1);
}
