.toolbar-3VxjT {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	z-index: 3;

	min-height: 80px;
	padding: 16px 0;

	background: #ffffff;
	box-shadow: 0 -7px 20px 0 rgba(0, 0, 0, 0.03);
}

.inner-3EQIz {
	max-width: 1100px;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.actions-2vm2j {
	display: flex;
	align-items: center;
	min-height: 48px;
}

.actions_left-333Ox {
	flex: 1;
}

.actions_right-3SFKM {
	margin-left: auto;
}

@media (max-width: 1024px) {
	.inner-3EQIz {
		padding: 0 20px;
	}
}

@media screen and (max-width: 600px) {
	.inner-3EQIz {
		padding: 0 20px;
	}

	.actions-2vm2j {
		justify-content: space-between;
	}
}

.price-1BI6R {
	margin-right: 24px;

	font-weight: 600;
	font-size: 22px;

	text-align: right;
}

.priceInMiles-3TgTI .currency-YChzo {
	padding-left: 6px;

	font-weight: 500;
}

.backButton__wrapper-F5E7k {
	display: flex;
	align-items: center;

	color: var(--text-pale);

	cursor: pointer;
}

button.backButton-3vCpC {
	flex-shrink: 0;
	margin-right: 10px;
	padding: 0;

	color: var(--text-pale);

	background-color: var(--bg-fill);
}

button.backButton-3vCpC > svg {
	transform: rotate(180deg);
}

button.backButton-3vCpC:hover {
	background-color: var(--gray05);
}

/* stylelint-disable-next-line block-no-empty */
button.button-3Ho0i {
}

button.button_disabled-3YMlp {
	background-color: var(--light-gray);
}

.miles-evrQU {
	display: flex;
	align-items: center;
	margin-top: 5px;

	color: #595959;
	font-weight: 400;
	font-size: 14px;

	white-space: nowrap;
}

.miles__money-3tpUw {
	padding-right: 5px;
}

.miles__hint-2NHzN {
	width: 20px;
	height: 20px;
	margin-bottom: 1px;
	margin-left: 8px;

	font-size: 16px;

	cursor: pointer;
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjY2NjUgOS45OTk4NEMxLjY2NjUgNS4zOTk4NCA1LjM5OTg0IDEuNjY2NSA5Ljk5OTg0IDEuNjY2NUMxNC41OTk4IDEuNjY2NSAxOC4zMzMyIDUuMzk5ODQgMTguMzMzMiA5Ljk5OTg0QzE4LjMzMzIgMTQuNTk5OCAxNC41OTk4IDE4LjMzMzIgOS45OTk4NCAxOC4zMzMyQzUuMzk5ODQgMTguMzMzMiAxLjY2NjUgMTQuNTk5OCAxLjY2NjUgOS45OTk4NFpNMTAuODMzMiA5LjE2NjUxVjE0LjE2NjVIOS4xNjY1VjkuMTY2NTFIMTAuODMzMlpNOS45OTk4MyAxNi42NjY1QzYuMzI0ODMgMTYuNjY2NSAzLjMzMzE2IDEzLjY3NDggMy4zMzMxNiA5Ljk5OTgzQzMuMzMzMTYgNi4zMjQ4MyA2LjMyNDgzIDMuMzMzMTcgOS45OTk4MyAzLjMzMzE3QzEzLjY3NDggMy4zMzMxNyAxNi42NjY1IDYuMzI0ODMgMTYuNjY2NSA5Ljk5OTgzQzE2LjY2NjUgMTMuNjc0OCAxMy42NzQ4IDE2LjY2NjUgOS45OTk4MyAxNi42NjY1Wk0xMC44MzMyIDUuODMzMTdWNy40OTk4M0g5LjE2NjVWNS44MzMxN0gxMC44MzMyWiIgZmlsbD0iI0JBQkFCQSIvPgo8L3N2Zz4K);
}
