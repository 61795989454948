.dayStyles__dayWithPrice-3szMv {
	font-size: 16px;
	font-weight: 500;
}

.dayStyles__dayWithPrice-3szMv.dayStyles__dayStartPeriod-3Vc-z {
	border-radius: 4px 0 0 4px;
}

.dayStyles__dayWithPrice-3szMv.dayStyles__daySelected-3cC8t {
	background: var(--brand-1-0);
}

.dayStyles__dayEndPeriod-4eg0q {
	border-radius: 0 4px 4px 0;
}

.dayStyles__dayWithPrice-3szMv:hover {
	border: 1px solid var(--brand-1-0) !important;
}

.dayStyles__dayPrice-24ctV {
	font-size: 10px;
}

.monthHeaderStyles__monthHeaderSelectYear-3fDdt,
.monthHeaderStyles__monthHeaderSelectMonth-29Fjy {
	font-size: 18px;
	font-weight: 700;
}

.monthHeaderStyles__monthHeaderSelectMonth-29Fjy {
	color: var(--text-base);
}

.monthHeaderStyles__monthHeaderSelectYear-3fDdt {
	color: var(--text-pale);
}

.weeklyHeaderStyles__day-4_yck {
	font-size: 16px;
	font-weight: 500;
}

.weeklyHeaderStyles__dayOfWeekNameWithPrices-2qbgA span {
	color: var(--text-pale);
	font-weight: 500;
}

button.searchFormDatepickerStyles__footerDone-GT-0s {
	background-color: var(--brand-1-0);
}

button.searchFormDatepickerStyles__footerDone-GT-0s:hover {
	background-color: var(--brand-1--1);
}

.monthsWrapper__navPrev-1Cvx-,
.monthsWrapper__navNext-11evh {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjODA4MDgwIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=) no-repeat center;

	transition: background 0.15s;
}

.monthsWrapper__navPrev-1Cvx-:hover,
.monthsWrapper__navNext-11evh:hover {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8Y2lyY2xlIGN4PSIyNiIgY3k9IjI1IiByPSIxNiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8cGF0aCBkPSJNMjcuNTkgMTlMMjkgMjAuNDFMMjQuNDIgMjVMMjkgMjkuNTlMMjcuNTkgMzFMMjEuNTkgMjVMMjcuNTkgMTlaIiBmaWxsPSIjZjY5NTVlIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MiIgaGVpZ2h0PSI1MiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQgZHk9IjEiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=) no-repeat center;
}

@media screen and (max-width: 600px) {
	.searchFormDatepickerStyles__dialogActiveDate-2ro97 {
		color: var(--text-accent);
	}

	.searchFormDatepickerStyles__dialogDates-3cCAX {
		font-size: 18px;
		font-weight: 700;
	}

	.dayStyles__dayStartPeriod-3Vc-z.dayStyles__daySelected-3cC8t {
		border-radius: 100px 0 0 100px;
	}

	.dayStyles__dayEndPeriod-4eg0q.dayStyles__daySelected-3cC8t {
		border-radius: 0 100px 100px 0;
	}

	div.searchFormDatepickerStyles__footerDone-GT-0s,
	div.searchFormDatepickerStyles__footerClear-2k3iJ {
		padding: 10px 20px;

		border-radius: 4px;
	}

	div.searchFormDatepickerStyles__footerDone-GT-0s {
		background: var(--brand-3-0);
	}

	div.searchFormDatepickerStyles__footerClear-2k3iJ {
		color: var(--text-base);

		opacity: 1;
		background-color: var(--white);
	}
}
