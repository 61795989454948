.title-2x0Wy {
	display: flex;
	grid-gap: 6px;
	gap: 6px;
	align-items: center;
	height: 48px;

	padding: 0 8px 0 14px;

	border-radius: 40px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.2;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);

	background: var(--white);
}

.title-2x0Wy:after {
	position: relative;
	top: 1px;

	margin: 0 7px;

	transform: rotate(0);
}

.title_open-2FV5m:after {
	transform: rotate(180deg);
}

.currentSorting-3JGhL {
	color: var(--text-base);
}

button.flightType-Q-Bhl,
button.flightType-Q-Bhl:hover {
	background: var(--white);
}

button.flightType-Q-Bhl.flightType__active-1U0nd {
	padding-left: 20px;
	padding-right: 10px;
}

button.flightType-Q-Bhl.flightType__active-1U0nd:before {
	display: none;
}

button.flightType-Q-Bhl.flightType__active-1U0nd:after {
	width: 24px;
	height: 24px;

	margin-left: 4px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNC4yMTIzIDE1Ljk0OTdMMTAuNSAxMi4yMzc0TDYuNzg3NjcgMTUuOTQ5N0w1LjU1MDIzIDE0LjcxMjNMOS4yNjI1NCAxMUw1LjU1MDIzIDcuMjg3NjdMNi43ODc2NyA2LjA1MDIzTDEwLjUgOS43NjI1NEwxNC4yMTIzIDYuMDUwMjNMMTUuNDQ5NyA3LjI4NzY3TDExLjczNzQgMTFMMTUuNDQ5NyAxNC43MTIzTDE0LjIxMjMgMTUuOTQ5N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
	content: '';
}

div.paper-2glu1 {
	min-width: 210px;
}

button.mobile__list__button-3jukd:not(.mobile__list__button_inactive-1e2QC):before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS40NjQxIDE3LjY3NzNMMjIuMDcwNyA3LjA3MDY2TDE5Ljk0OTMgNC45NDkzNEw5LjM0Mjc0IDE1LjU1NTlMNC4zOTM0IDEwLjYwNjZMMi4yNzIwOCAxMi43Mjc5TDcuMjIxNDIgMTcuNjc3M0w5LjM0Mjc0IDE5Ljc5ODZMOS4zNDMxNSAxOS43OTlMMTEuNDY0NSAxNy42Nzc3TDExLjQ2NDEgMTcuNjc3M1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) no-repeat center / contain;
}

@media screen and (max-width: 600px) {
	.title-2x0Wy {
		padding: 0 14px 0 12px;

		font-size: 14px;
	}

	.title-2x0Wy:before {
		position: relative;
		top: 0;

		transform: none;
	}
}
