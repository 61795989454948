.container-3yUB7 {
	grid-gap: 12px;
	gap: 12px;
}

.root-2BD2P > div {
	min-height: 64px;
}

.root-2BD2P > div:not(:first-child) {
	border-top: 1px solid var(--bg-elements);
}

.preview-2sPPG,
.orderCard__root-2jAo1 {
	border-radius: 4px;
	overflow: hidden;
}

.container-3yUB7.type_account-3stEO .preview-2sPPG > div {
	border-radius: 6px 0 0 6px;
}

.root-2BD2P {
	border-radius: 0 6px 6px 0;
}

button.openOrder--Oa3Z {
	margin-bottom: 0;
	padding: 10px 24px;

	border: none;
	border-radius: 4px;

	color: var(--bg-white);
	font-size: 14px;
	font-style: normal;
	line-height: 130%;
	text-align: center;

	background: var(--brand-3-0);
}

button.openOrder--Oa3Z:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.orderStatus__confirmed-3SavW .orderStatus__icon-1v-dT,
.orderStatus__root-35nVF.orderStatus__type_review-1JM2n.orderStatus__confirmed-3SavW {
	color: var(--success-text);
}

.orderBooking__root-4F0fl.orderBooking__type_review-1t0D- .orderBooking__orderCode-1A6f5 {
	font-size: 16px;
	font-weight: 700;
	color: var(--text-base);
}

.orderCard__root-2jAo1 {
	color: var(--text-mid);
}

button.orderBooking__download-3Pmm7 {
	font-weight: 700;
}

button.orderChange__button-1rYOg {
	font-size: 14px;
	font-weight: 500;
}

button.orderBooking__download-3Pmm7,
button.orderBooking__download-3Pmm7:hover,
button.orderChange__button-1rYOg,
button.orderChange__button-1rYOg:hover {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);

	background: var(--white);
}

button.orderBooking__download-3Pmm7:hover {
	font-weight: 700;
}

button.orderChange__button-1rYOg:hover {
	font-size: 14px;
	font-weight: 500;
}

.orderPreview__inner-32A_D {
	justify-content: flex-end;
}

.orderPassengers__control-2mqho,
.orderTickets__link-3xiHL {
	color: var(--text-link);
	font-size: 14px;
}

.orderCard__container-13B2W {
	border-radius: 4px;
	overflow: hidden;
}

.paymentStatus__icon-1a0f3 {
	color: var(--error-icon);
}

.orderCardControls__root-3jJIb {
	border-bottom: 1px solid #e3e3e3;

	color: var(--text-mid);
}

.orderCardControls__root-3jJIb:not(:first-child) {
	border-top: none;
}

@media (min-width: 1024px) {
	.orderCard__root-2jAo1 .paymentStatus__root-2YEga,
	.orderCard__root-2jAo1 .orderCardControls__root-3jJIb {
		order: -1;
	}
}

@media (max-width: 768px) {
	.preview-2sPPG {
		border-radius: 4px 4px 0 0;
	}

	.orderCard__root-2jAo1 {
		margin-top: -12px;

		border-radius: 12px 12px 0 0;
	}
}

@media screen and (max-width: 600px) {
	.orderCardControls__root-3jJIb {
		background: var(--white);
	}

	button.orderCardControls__button-i4_e- {
		background-color: var(--brand-1-0);
	}

	button.orderBooking__download-3Pmm7,
	button.orderBooking__download-3Pmm7:hover {
		color: var(--white);

		background-color: var(--brand-1-0);
	}

	button.orderChange__button-1rYOg,
	button.orderChange__button-1rYOg:hover {
		border: 1px solid var(--brand-1-3);

		color: var(--text-link);
	}

	.orderCardControls__root-3jJIb .commonItem__buttons-2shOF {
		margin-left: 28px;
	}

	.root-2BD2P > div {
		min-height: 20px;
		align-items: flex-start;
	}

	.preview-2sPPG {
		border-radius: 0;
		overflow: visible;
		overflow: initial;
	}
}
