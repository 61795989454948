.container-3EWxq {
	display: flex;
	min-height: 200px;
}

.root-25dJS {
	position: relative;

	display: flex;
	flex: 1;
	flex-direction: column;

	color: var(--gray70);
	font-weight: 400;

	background: var(--white);
}

.container-3EWxq.type_account-3Hz4r {
	margin-bottom: 12px;
}

.container-3EWxq.type_review-2O0Ao .root-25dJS {
	overflow: hidden;

	font-size: 16px;
	line-height: 1.3;
}

.container-3EWxq.type_review-2O0Ao button {
	height: 33px;
	margin-top: 12px;
	margin-bottom: 12px;

	line-height: 33px;
}

.container-3EWxq.type_account-3Hz4r .root-25dJS {
	border-radius: 0 6px 6px 0;

	font-size: 16px;
	line-height: 1.3;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
	overflow: hidden;
}

.preview-121bm {
	width: var(--order-card-preview-width);
}

.container-3EWxq.type_account-3Hz4r .preview-121bm,
.container-3EWxq.type_account-3Hz4r .preview-121bm > div {
	border-radius: 6px 0 0 6px;
}

.status-Vdbt-,
.booking-gpudR,
.tickets-3cqev,
.passengers-12C5d {
	min-width: 0;
	padding: 0 20px;
}

.item-1rcKV {
	display: flex;
	align-items: center;
	min-height: 44px;
}

.item-1rcKV:not(:first-child) {
	border-top: 1px solid #e3e3e3;
}

.status-Vdbt- {
	grid-area: status;
}

.booking-gpudR {
	grid-area: booking;
}

.passengers-12C5d {
	grid-area: passengers;
	padding-top: 12px;
	padding-bottom: 12px;
}

.type_review-2O0Ao .passengers-12C5d {
	flex: 1;
}

.controls-2_lYQ {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
}

.buttons-12eJ2 {
	flex: 1;
	padding: 16px 12px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	grid-gap: 16px;
	gap: 16px;

	border-top: 1px solid #e3e3e3;
}

button.openOrder-3vkLI,
button.openOrder-3vkLI:hover {
	color: var(--base);
	text-align: center;
	font-size: 14px;
	font-weight: 700;

	background: var(--brand1-4);
}

/* stylelint-disable */
.orderConfirmed-PUra4 {
}

/* stylelint-enable */
@media (max-width: 768px) {
	.container-3EWxq {
		flex-direction: column;
	}

	.preview-121bm {
		width: 100%;
		height: 200px;
	}

	.container-3EWxq.type_review-2O0Ao .root-25dJS {
		overflow: visible;
	}

	.container-3EWxq.type_review-2O0Ao .root-25dJS:after {
		position: absolute;
		top: 2px;
		right: 0;
		left: 0;
		z-index: 10;

		height: 14px;

		border-radius: 12px 12px 0 0;

		transform: translateY(-100%);
		background: #ffffff;
		content: '';
	}

	.container-3EWxq.type_account-3Hz4r {
		border-radius: 8px;
	}

	.container-3EWxq.type_account-3Hz4r .root-25dJS {
		border-radius: 0 0 8px 8px;
	}

	.container-3EWxq.type_account-3Hz4r .preview-121bm {
		border-radius: 8px 8px 0 0;
	}

	.buttons-12eJ2 {
		align-items: center;
		justify-content: space-between;
		grid-gap: 16px;
		gap: 16px;

		border-top: 1px solid #e3e3e3;
	}
}
