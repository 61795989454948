

.service-2_Yzw {
	display: flex;
	flex-direction: column;

	margin-bottom: 12px;

	border-radius: 4px;

	overflow: hidden;
}

.card--mRah {
	width: 100%;
	padding: 12px;

	font-weight: 700;
	font-size: 16px;
	font-style: normal;
	line-height: 130%; /* 20.8px */

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
	background: var(--white);
}

.info-18Lbe {
	display: flex;
	align-items: center;
}

.card--mRah .info-18Lbe .seat-3xoGB {
	width: 36px;
	min-width: 36px;
	height: 36px !important;
	max-height: 36px;

	border-radius: 4px !important;

	pointer-events: none;
}

.seat-3xoGB > div {
	display: none !important;
}

.image-2Lo7- {
	width: 100%;
}

.comfort-3pD3U {
	margin-left: 8px;
}

.freeSeatContainer-2oX5- {
	padding: 12px;
}

.freeSeatItem-2tfuL {
	width: 42px;
	height: 42px;
}

.money-2MPw4 {
	margin-left: 10px;
}

.description-2cwwu {
	margin-top: 10px;

	color: var(--gray70);
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
}
