.container-3c4vX {
	padding: 0;

	border: none;

	background: transparent;
}

.description-5UIz7 {
	padding: 12px;

	border: 1px solid var(--text-accent);
	border-radius: 4px;

	background: var(--bg-white);
}

.switch__control-3UicS.switch__active-1ciO1:after {
	background: var(--brand-3-2);
}

.switch__icon-3X8Py {
	color: var(--brand-3-2);
}

.switch__label-3bC79 {
	color: var(--brand-1-0);
	font-size: 16px;
}

button.sureModalButton-3LRPg {
	background: var(--brand-3-0);
}

button.sureModalButton-3LRPg:hover {
	background: var(--brand-3-1);
}
