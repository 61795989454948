

.legNumber-2m6zW {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 36px;
	height: 36px;

	margin-right: 16px;

	font-weight: 500;

	font-size: 16px;
	line-height: 1.3;

	background: var(--brand-1-0);
}

.title__text-HGZT4 {
	color: var(--text-base);
}

.title__cities-ffMPP {
	color: var(--brand-1--2);
}

.title__cities-ffMPP svg {
	display: none;
}

.title__cities-ffMPP span:first-of-type:after {
	content: ' \2013';

	margin-right: 6px;
}

.title__text-HGZT4,
.title__cities-ffMPP {
	font-weight: 700;
	line-height: 1.3;
}

button.diagram-JzkLq {
	font-size: 16px;
}

button.diagram-JzkLq svg {
	color: var(--brand-1-1);

	opacity: 1;
}
