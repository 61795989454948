/* stylelint-disable */

.row__PartWrpWithTechFacilities-wZkeO {
	flex: 1;
}

.seatMap-2kBHL {
	width: auto;
	padding: 0 5px;
}

.row-QDAGV {
	padding: 0;
}

.row-QDAGV:first-of-type {
	padding-top: 30px;
}

.row-QDAGV:first-of-type .seatWrapper_newClass-3b8jl {
	padding-top: 0;
}

.wings-1MYDI:before,
.wings-1MYDI:after {
	background: var(--bg-elements);
}

.row__serviceHeader-13uRn {
	display: none;
	text-transform: none;
	border-radius: 4px 4px 0px 0px;
}

.row__serviceHeader__wrapper-3J4ex {
	text-transform: none;
}

.row__serviceHeader-13uRn.row__serviceHeader_preferred-4qJvY {
	border-color: var(--seat-front);
	background: var(--seat-front);
}

.row__serviceHeader-13uRn.row__serviceHeader_preferred-4qJvY .row__serviceHeader__wrapper-3J4ex {
	background: var(--seat-front);
}

.row-QDAGV .row__rowPartWrp-26r6O:first-child {
	justify-content: flex-start;
}

.row-QDAGV .row__rowPartWrp-26r6O:last-child {
	justify-content: flex-end;
}

.seatMap_wide-1dJ_9 .exit-1ldFF + .row_withExit-3p8TW + .exit-1ldFF {
	display: none;
}

.seatMap_wide-1dJ_9 .exit-1ldFF:has(+ .row_withExit-3p8TW + .exit-1ldFF) .exit__label-1z6hC {
	margin-top: 65px;
	z-index: 1;
}

.seatMap__wrapper-37QBV {
	margin-top: 4px;
	border-bottom: none;
}

.exit__label-1z6hC {
	color: var(--text-light);
}

/* tooltip */
div.tooltip-BZyyc,
div.tooltip-BZyyc.tooltip_recommend-2_g_S {
	border-radius: 6px;
	background: var(--brand-1-0);
}

div.tooltip-BZyyc:after,
div.tooltip-BZyyc.tooltip_recommend-2_g_S:after {
	border-top-color: var(--brand-1-0);
}

div.tooltip-BZyyc .info-1LW4A {
	background: var(--brand-1-1);
}

div.tooltip_withInfo-3Vjl_.tooltip-BZyyc:after {
	border-top-color: var(--brand-1-1);
}

/* seat */
button.seat-2pNki {
	height: 40px;
	border: 3px solid transparent !important;
}

button.seat_standart-1YpjF {
	border-color: var(--seat-standart);
	background: var(--seat-standart);
}

button.seat-2pNki.seat_combinedRight-2qD1y.seat_standart-1YpjF,
button.seat-2pNki.seat_combinedLeft-31jix.seat_standart-1YpjF,
button.seat-2pNki.seat_occupied-1zKbY.seat_standart-1YpjF,
button.seat-2pNki.seat_standart-1YpjF:hover {
	background: var(--seat-standart);
}

button.seat-2pNki.seat_business-2idZd {
	border-color: var(--seat-business);
	background: var(--seat-business);
}

button.seat-2pNki.seat_combinedRight-2qD1y.seat_business-2idZd,
button.seat-2pNki.seat_combinedLeft-31jix.seat_business-2idZd,
button.seat-2pNki.seat_occupied-1zKbY.seat_business-2idZd,
button.seat-2pNki.seat_business-2idZd:hover {
	background: var(--seat-business);
}

button.seat-2pNki.seat_preferred-3SxW4 {
	border-color: var(--seat-economy-front);
	background: var(--seat-economy-front);
}

button.seat-2pNki.seat_free-3cxlh {
	border-color: var(--seat-free);
	background: var(--seat-free);
}

button.seat-2pNki.seat_combinedRight-2qD1y.seat_free-3cxlh,
button.seat-2pNki.seat_combinedLeft-31jix.seat_free-3cxlh,
button.seat-2pNki.seat_occupied-1zKbY.seat_free-3cxlh,
button.seat-2pNki.seat_free-3cxlh:hover {
	background: var(--seat-free);
}

button.seat-2pNki:hover {
	border-color: var(--brand-1-0);
}

.seat_occupied-1zKbY.seat-2pNki:not(.seat_confirmed-zYxVD):hover:after {
	top: -1px;
	width: 24px;
	height: 24px;

	background-size: contain;
}

button.seat-2pNki.seat_unavailable-t2zxw,
button.seat-2pNki.seat_small-wcOGn.seat_unavailable-t2zxw {
	border-color: var(--seat-unavailable);
	background: var(--seat-unavailable);
}

button.seat-2pNki.seat_combinedRight-2qD1y {
	border-right: none;
	border-color: var(--brand-1-0);
}

button.seat-2pNki.seat_combinedLeft-31jix {
	border-left: none;
	border-color: var(--brand-1-0);
}

button.seat-2pNki.seat_combinedRight-2qD1y .number-3xRTb,
button.seat-2pNki.seat_combinedLeft-31jix .number-3xRTb,
.number-3xRTb,
.seat_occupied-1zKbY .number-3xRTb,
.seat-2pNki:not(.seat_unexistent-19-uj):not(.seat_unavailable-t2zxw):not(.seat_occupied-1zKbY):hover .number-3xRTb {
	color: var(--text-mid);
}

.seat_occupied-1zKbY .number-3xRTb {
	font-size: 14px;
}

button.buttonControl-1379x {
	font-weight: 700;
	background: var(--brand-3-0);
}

button.buttonControl-1379x:hover {
	background: var(--brand-3-1);
}

button.seat-2pNki[data-rfisc='SAS']:not(.seat_unavailable-t2zxw) {
	background: #f3eaa4;
	border-color: #f3eaa4;
}

button.seat-2pNki[data-rfisc='STR']:not(.seat_unavailable-t2zxw) {
	background: #e0c1f8;
	border-color: #e0c1f8;
}

button.seat-2pNki[data-rfisc='PRS']:not(.seat_unavailable-t2zxw) {
	background: #afcfff;
	border-color: #afcfff;
}

button.seat-2pNki[data-rfisc='PSA']:not(.seat_unavailable-t2zxw) {
	background: #cbcdfd;
	border-color: #cbcdfd;
}

button.seat-2pNki[data-rfisc='SPE']:not(.seat_unavailable-t2zxw) {
	background: #edc5ff;
	border-color: #edc5ff;
}

button.seat-2pNki[data-rfisc='ST7']:not(.seat_unavailable-t2zxw) {
	background: #ffcac1;
	border-color: #ffcac1;
}

button.seat-2pNki[data-rfisc='SMR']:not(.seat_unavailable-t2zxw) {
	background: #fee9a7;
	border-color: #fee9a7;
}

button.seat-2pNki[data-rfisc='SAS']:not(.seat_unavailable-t2zxw) {
	background: #fdff95;
	border-color: #fdff95;
}

button.seat-2pNki[data-rfisc='ST2']:not(.seat_unavailable-t2zxw) {
	background: #dbf3c2;
	border-color: #dbf3c2;
}

button.seat-2pNki[data-rfisc='FRF']:not(.seat_unavailable-t2zxw) {
	background: #c8f2ff;
	border-color: #c8f2ff;
}

button.seat-2pNki.seat_occupied-1zKbY,
button.seat-2pNki.seat_standart-1YpjF.seat_occupied-1zKbY,
button.seat-2pNki.seat_business-2idZd.seat_occupied-1zKbY,
button.seat-2pNki.seat_preferred-3SxW4.seat_occupied-1zKbY,
button.seat-2pNki.seat_recommend-1Jg3B,
button.seat-2pNki.seat_standart-1YpjF.seat_recommend-1Jg3B,
button.seat-2pNki.seat_business-2idZd.seat_recommend-1Jg3B,
button.seat-2pNki.seat_preferred-3SxW4.seat_recommend-1Jg3B {
	border-color: var(--brand-3-0);
	background: var(--brand-3-0);
}

button.seat-2pNki.seat_occupied-1zKbY:hover {
	background: var(--brand-3-0);
}

button.seat-2pNki.seat_occupied-1zKbY .number-3xRTb {
	color: var(--white);
}

.seat-2pNki.seat_recommend-1Jg3B.seat_recommendCurrent-2UFOp,
button.seat-2pNki.seat_recommend-1Jg3B.seat_recommendCurrent-2UFOp {
	background: var(--white);
}

.seat-2pNki.seat_recommend-1Jg3B.seat_recommendCurrent-2UFOp .number-3xRTb {
	color: var(--brand-3-0);
}

button.seat_small-wcOGn {
	border-radius: 8px;
}

button.seat-2pNki.seat_small-wcOGn:not(.seat_business-2idZd) {
	max-width: 20px;
}

.seat_small-wcOGn:not(.seat_occupied-1zKbY) .number-3xRTb {
	display: block;
}

.seat_small-wcOGn.seat_business-2idZd {
	height: 40px;
	border-radius: 8px;
}

.exit-1ldFF {
	height: 30px;
}

.exit-1ldFF .exit__label-1z6hC {
	margin-top: -5px;
}

.exit-1ldFF .exit__label-1z6hC:before {
	top: -5px;
}

.row__toilet-kLhAe {
	display: flex;
	margin-bottom: 10px;
}

@media screen and (max-width: 320px) {
	.seatMap-2kBHL {
		width: 294px;
	}
}

button.seat_small-wcOGn {
	width: 20px !important;
	min-width: auto;
	max-width: 20px;
}

@media screen and (max-width: 600px) {
	button.seat_unavailable-t2zxw {
		border: 0;
	}

	.wings-1MYDI {
		display: none;
	}
}
