.wrapper-2WpOG {
	position: relative;

	width: 100%;

	background-color: var(--quickSearchForm-background);
}

.summary-3cA4A {
	max-width: 75%;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.outer__summary-3AJYG,
.outer__summary_open-2KWfW {
	background: var(--quickSearchForm-background);
}

@media screen and (max-width: 600px) {
	.wrapper-2WpOG {
		height: auto;

		background-color: var(--quickSearchForm-background-mobile);
	}
}

.outer-1rXaJ {
	position: relative;
	z-index: 6;
}

.inner-10Xd8 {
	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
}

.inner__summary-3SvF0 {
	display: flex;
	align-items: center;

	max-width: 1100px;
	margin: 0 auto;
}

@media screen and (max-width: 600px) {
	.inner-10Xd8 {
		height: 46px;
		display: block;

		box-shadow: none;
	}
}

.form-1grR_ {
	padding-bottom: 20px;

	background: var(--common-blue);
}

.form__wrapper-bmaug {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0;
}

@media screen and (max-width: 600px) {
	.form-1grR_ {
		position: static;

		border-top: none;
	}
}

@media (max-width: 1024px) {
	.summary-3cA4A {
		max-width: 100%;
	}

	div.promoCode-32ofK {
		margin-left: 6px;
	}

	.form-1grR_ {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.underlay-2Qmpf {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	cursor: pointer;
	opacity: 0.7;
	background-color: var(--dark-gray);

	transition: opacity 0.2s;
}

.underlay_hidden-3ixtn {
	z-index: -99999;

	opacity: 0;

	pointer-events: none;
}

.children_hidden-myZ_C {
	z-index: -99999;

	opacity: 0;

	animation-duration: 150ms;
	animation-name: hide-1iNPG;
}

.promoCode__wrp-3ZIsR {
	position: relative;

	display: flex;
	padding: 13px 20px;

	background: var(--quickSearchForm-background-mobile);
}

.promoCode-32ofK {
	flex-shrink: 0;
	margin-left: auto;

	color: var(--quickSearchForm-summary-trigger-color);
	font-weight: 400;

	font-size: 14px;

	white-space: nowrap;
}

@media screen and (max-width: 600px) {
	.promoCode-32ofK {
		margin: 0 auto;

		color: #ffffff;
	}

	.promoCode__wrp-3ZIsR:before {
		position: absolute;
		top: -1px;

		width: calc(100% - 40px);

		border-top: 1px solid #ffffff;
		content: '';
	}

	.form-1grR_ {
		padding: 0;
	}
}

@keyframes hide-1iNPG {
	from {
		z-index: 1;

		opacity: 1;
	}

	to {
		z-index: -999999;

		opacity: 0;
	}
}
