

.email-schP7 {
	width: 100%;
	height: 64px;
	margin-top: 0;
	padding: 0 35px;

	color: #595959;
	font-weight: 500;
	font-size: 17px;
	line-height: 64px;
	text-align: center;

	background-color: #e4e4e4;
}

button.button-1NZjX {
	margin-top: 12px;
}
