.airline__name-3qSAw,
.airline__number-1qyjQ {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

.flight-3tagq > svg {
	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.aircraft__name-19eIs {
		margin-left: 0;
	}
}
