.summary-1sVsJ {
	z-index: 3;

	display: flex;
	align-items: center;
	min-height: 56px;
	padding-right: 20px;

	cursor: pointer;
}

@media screen and (max-width: 600px) {
	.summary-1sVsJ {
		min-height: 46px;
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

@media (max-width: 1024px) {
	.summary-1sVsJ {
		padding: 0 20px;
		width: 100%;
		height: 46px;
	}
}

.route-3Em00 {
	color: var(--quickSearchForm-summary-route-color);

	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.route__icon-13cr5 {
	margin: 0 8px;
}

.route__separator-1vZej {
	margin: 0 8px;
}

@media screen and (max-width: 600px) {
	.route-3Em00 {
		font-size: 14px;
		line-height: 16px;
		color: #ffffff;
	}
}

.passengers-1kyGp,
.dates-Ibv3f,
.editButtonWrapper-2_f2d {
	position: relative;

	margin-left: 12px;
	padding-left: 12px;
}

.passengers-1kyGp,
.dates-Ibv3f {
	color: var(--quickSearchForm-summary-passengers-color);
	font-weight: 500;

	font-size: 18px;
	line-height: 1.3;
}

.passengers-1kyGp {
	max-width: 50%;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.dates-Ibv3f {
	flex-shrink: 0;
}

.passengers-1kyGp:before,
.dates-Ibv3f:before,
.editButtonWrapper-2_f2d:before {
	position: absolute;

	top: 0;
	bottom: 0;
	left: 0;

	display: block;

	width: 4px;
	height: 4px;
	margin: auto;

	border-radius: 50%;

	background: var(--quickSearchForm-summary-trigger-border-color);
	content: '';
}

.dates-Ibv3f span:not(:last-child) {
	margin-right: 5px;
}

.dates-Ibv3f span:not(:first-child):before {
	content: '- ';
}

@media screen and (max-width: 600px) {
	.passengers-1kyGp {
		max-width: 100%;

		font-size: 14px;
	}

	.dates-Ibv3f {
		display: none;
	}

	.editButtonWrapper-2_f2d {
		position: absolute;
		right: 20px;

		margin-left: 0;

		margin-left: initial;
		padding-left: 0;
		padding-left: initial;
	}

	.editButtonWrapper-2_f2d:before {
		content: normal;
		content: initial;
	}
}
