

button.segment-3poTD {
	color: var(--brand-1-0);

	background: var(--white);
}

button.segment-3poTD:not(:last-child) {
	margin-right: 0;
}

.alignmentStub-14rkU {
	flex: unset;
}

.segments-3E45E {
	grid-gap: 12px;
	gap: 12px;
}

.wrapper_center-VmHm3 .segments-3E45E {
	padding-right: 0;
}

.container_seats-31RPb .segments-3E45E {
	justify-content: center;
	padding-right: 0;
}

.title-3n-h1 {
	position: absolute;
}

.checked-3yVuJ {
	display: none;
}
