

.wrapper-3fg5p div {
	color: var(--text-mid);
}

.wrapper-3fg5p .text-2LZr9 {
	font-size: 16px;
}

.wrapper-3fg5p svg path {
	fill: var(--light-text);
}
