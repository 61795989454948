

.dates-3q5ln {
	display: flex;
	grid-gap: 6px;
	gap: 6px;
	align-items: center;

	height: 48px;

	padding: 0 14px;

	border-radius: 40px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 14px;

	background: var(--white);
}

.dates-3q5ln:before {
	position: relative;
}
