.details-3i5Xk {
	background: var(--brand-1-5);
}

.wrapper-3YhRI,
.details-3i5Xk {
	border-radius: 4px;
}

.content-1miOD {
	padding: 7px 30px 28px;
}

.oneMethod-2G3en .content__title-2fcoK {
	font-size: 22px;
	font-weight: 500;
	color: var(--text-base);
}

button.details__button-29rg4 {
	color: var(--text-link);
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.wrapper-3YhRI {
		padding: 0 0 64px;

		border-radius: 12px 12px 0 0;

		background: var(--white);
	}

	.details-3i5Xk {
		order: 1;
		margin: 0 12px 12px 12px;
		padding: 12px;

		border-radius: 4px;

		background: var(--bg-fill);
	}

	.content-1miOD {
		padding: 20px;
	}

	.content-1miOD iframe {
		width: calc(100% + 36px);
		margin-left: -18px;
	}
}
