.header-3qmoG {
	grid-gap: 12px;
	gap: 12px;
	min-height: 30px;
	padding: 0 5px;

	font-weight: 700;
	font-size: 18px;
	line-height: 1.4;
}

.promo_message-1AmSV {
	display: flex;
	grid-gap: 4px;
	gap: 4px;
	max-width: -moz-fit-content;
	max-width: fit-content;
	align-items: center;
	padding: 6px 12px;

	border-radius: 100px;

	color: var(--bg-white);
	font-weight: 550;
	font-size: 14px;
	line-height: 130%;

	background: var(--brand-1-0);
}

.promo_message-1AmSV:after {
	content: '';

	width: 20px;
	height: 20px;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDMuMzMzMzFMOC44MjUwNCA0LjUwODMxTDEzLjQ3NSA5LjE2NjY1SDMuMzMzMzdWMTAuODMzM0gxMy40NzVMOC44MjUwNCAxNS40OTE2TDEwIDE2LjY2NjZMMTYuNjY2NyA5Ljk5OTk4TDEwIDMuMzMzMzFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
	background-position: center center;
	background-size: 100%;
}

.header-3qmoG span {
	color: var(--success-icon);
}
