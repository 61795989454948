/* stylelint-disable */
.passenger-3WRGX {
	height: auto;
	min-height: 48px;

	color: var(--text-base);
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);

	padding: 8px 12px;
}

.avatar-31KFe {
	color: var(--text-light);
	background: var(--bg-fill);

	padding-top: 2px;
}

.payload-3UvAF {
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
}

.money-239Sv {
	padding-left: 8px;
	color: #161834;
	color: var(--brand-1-0, #161834);
}

.clear-h-bpN {
	width: auto;
	height: auto;
}

.passenger-3WRGX.active-11bgT .select-382P2,
.select-382P2 {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 400;
}

.passenger-3WRGX.active-11bgT {
	border: 1px solid var(--brand-1-2);
	color: var(--text-base);
	background: var(--bg-white);
}

.passenger-3WRGX.active-11bgT .avatar-31KFe {
	color: var(--bg-white);
	background: var(--brand-1-0);
}

.passenger-3WRGX.active-11bgT .money-239Sv {
	color: var(--brand-1-0);
}

.clear-h-bpN svg,
.passenger-3WRGX.active-11bgT .clear-h-bpN svg {
	color: var(--error-icon);
}

.passenger-3WRGX.passenger_recommend-2FR1S {
	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.passenger-3WRGX.passenger_recommend-2FR1S,
.passenger-3WRGX.passenger_recommend-2FR1S.active-11bgT {
	background: var(--bg-white);
}

.passenger-3WRGX.passenger_recommend-2FR1S .avatar-31KFe {
	color: var(--text-light);
	background: var(--bg-fill);
}

.passenger-3WRGX.passenger_recommend-2FR1S.active-11bgT .avatar-31KFe {
	color: var(--bg-white);
	background: var(--brand-1-0);
}

.passenger-3WRGX.passenger_recommend-2FR1S svg {
	color: var(--text-pale);
	vertical-align: middle;
}

/* passengers */
.recommend__icon-UqMOq {
	color: var(--text-link);
}

.notifications-2JKrv .notification-1hTQe {
	border-color: var(--warning-icon);
	background: var(--bg-white);
}

.notifications-2JKrv .notification-1hTQe svg {
	color: var(--text-link);
}

.notifications-2JKrv .notification-1hTQe div {
	color: var(--text-light);
}

.recommend__label-2gkdC svg {
	color: var(--text-link);
}

button.passengers__button-B-kKb {
	font-weight: 700;
	background: var(--brand-3-0);
}

button.passengers__button-B-kKb:hover {
	background: var(--brand-3-1);
}

button.passengers__button-B-kKb.passengers__button_clear-2gRKh {
	padding-left: 0;
	padding-right: 0;
	background: var(--bg-fill);
}

@media (max-width: 768px) {
	.passenger-3WRGX {
		box-shadow: none;
	}
}

.mobilePassenger__active-3Qfya {
	border-color: var(--text-accent);
}

.mobilePassenger__active-3Qfya .avatar-31KFe {
	color: var(--bg-white);
	background: var(--brand-1-0);
}

.mobilePassenger__doubleSeat-1v1fK {
	padding-top: 12px;
	border-top: 1px dashed #d1d1d1;
}

div.mobilePassenger__doubleSeat-1v1fK {
	padding-left: 12px;
	padding-right: 12px;
}

.item_withSeat-4kQl7 .avatar-31KFe {
	font-size: 12px;
}

@media (max-width: 768px) {
	.passenger-3WRGX {
		box-shadow: none;
	}
}
