div.modal-1-H6U {
	border-radius: 20px;
}

div.modal_rounded-2oy-l {
	border-radius: 20px;
}

.closeIcon-te75K {
	top: 8px;
	right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);

	background: var(--bg-fill);
}

.closeIcon-te75K svg {
	width: 16px;
	height: 16px;
}

div.modal_rounded-2oy-l .closeIcon-te75K {
	top: 8px;
	right: 8px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

@media screen and (max-width: 600px) {
	div.modal-1-H6U {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}
