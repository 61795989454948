

.travellerForm-Z9kkI {
	display: flex;
	flex-direction: column;
	grid-gap: 40px;
	gap: 40px;
	margin: 40px;
}

.travellerForm__content-3HYdD {
	display: flex;
	flex-direction: column;
	grid-gap: 40px;
	gap: 40px;
}

.travellerForm__contentControls-2Z4dY {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.travellerForm__actions-1hi-U {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	justify-self: flex-end;
	grid-gap: 12px;
	gap: 12px;
	flex: 1;
}

.description-DXovd {
	margin: 0;
	padding: 0;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-wrap: break-word;
	color: var(--text-light);
}

button.removeButton-3SC7Q {
	display: flex;
	grid-gap: 6px;
	gap: 6px;
	padding: 4px;

	border-radius: 4px;

	font-size: 14px;
	color: var(--text-light);
}

.travellerForm__actions-1hi-U button.saveButton-1kYYB {
	background: var(--brand-1-0);
}

.travellerForm__actions-1hi-U button.saveButton-1kYYB:hover {
	background: var(--brand-1-1);
}

.loader-1GHLD {
	position: relative;

	width: 40px;
	height: 40px;
	margin-left: 20px;
	padding: 6px;

	box-sizing: border-box;

	border-radius: 50%;

	background-color: var(--brand-1-0);
}

.success-3PN8B {
	background-color: var(--common-green);
}

.success__icon-3Rvr3 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

span.circularProgress-2D4IT {
	color: var(--white);
}
