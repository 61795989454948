.root-Eygo5 {
	margin: 0;

	border-radius: 12px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.includedBaggage__root-22RzM {
	padding-bottom: 40px;
}

.includedBaggage__root-22RzM .slick-dots {
	bottom: -30px;
}

.root-Eygo5:not(:last-child) {
	margin-right: 0;
}

.baggage_dialog_body-1rLDO .counter-2ulwT {
	color: red;
}

.baggage_dialog_root-2DdAf:has(.equipment_wrapper-30nGj) .counter_wrapper-3tFI7 {
	justify-content: flex-end;
}

.baggage_dialog_root-2DdAf:has(.equipment_wrapper-30nGj) .baggage_price__right-1UW5W {
	text-align: right;
}

.baggageList_root-31EeW .slick-arrow:hover:before {
	filter: invert(61%) sepia(71%) saturate(430%) hue-rotate(332deg) brightness(99%) contrast(95%);
}

.item-87MeS {
	border-radius: 4px;

	background: var(--bg-fill);
}

.flight-31IGS:last-child {
	margin-bottom: 12px;
}

.icon-3zq9S {
	color: var(--brand-1-0);

	background: var(--bg-fill);
}

.icon-3zq9S > svg {
	width: 44px;
	height: 44px;
}

button.button-2Ks-I,
button.control-3WvqA,
button.control-3WvqA.control__edit-1rvzV {
	display: flex;
	grid-gap: 4px;
	gap: 4px;
	justify-content: center;
	align-items: center;
	height: 34px;
	padding: 8px 16px;

	border: 1px solid var(--brand-1-0);
	border-radius: 100px;

	color: var(--brand-1-0);
	font-weight: 550;
	font-size: 14px;
	line-height: 130%;
	text-align: center;

	background: var(--bg-white);
}

button.button-2Ks-I:hover,
button.control-3WvqA:hover,
button.control-3WvqA.control__edit-1rvzV:hover {
	border: 1px solid var(--brand-1-0);

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.banner_image-3271f {
	display: none;
}

.banner_text-3sdd- {
	font-size: 24px;
	font-weight: 700;
	line-height: 100%;
	color: #333333;
}

.banner_wrapper-11af6 {
	height: 55px;

	background-color: var(--bg-white);
}

.header-2A1m6 {
	margin-bottom: 12px;

	border-bottom: 1px dashed var(--line-separator);
}

.total_price-hbMsu {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 130%;
}

.header_text-2mpoA {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 130%;
}

.options__succsess-1njca {
	color: var(--bg-white);
	font-weight: 700;
	font-size: 13px;
	line-height: 130%;
	text-align: center;

	background: var(--success-icon);
}

.options__text__succsess-KQW9X {
	color: var(--success-text);
}

.subtext-2COcg {
	color: var(--text-light);
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
}

.baggage_card_wrapper-1Q7jL {
	justify-content: space-between;
}

.baggage_card_info-D8BKO:has(.subtext-2COcg) {
	min-height: 70px;
}

.baggage_name-1RC8s {
	color: var(--text-base);
	text-align: center;
	font-size: 13px;
	font-weight: 400;
	line-height: 130%;
}

.baggage_size-17O7l {
	color: var(--text-light);
	text-align: center;
	font-size: 13px;
	font-weight: 400;
	line-height: 130%;
}

.baggage_price-C3IyZ {
	color: var(--text-light);
	text-align: center;
	font-size: 13px;
	font-weight: 400;
	line-height: 130%;
}

button.baggage_add_icon-2fn7I,
button.baggage_add_icon-2fn7I:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px;

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.baggage_dialog_header_modal_close-W6aIz svg path {
	fill: var(--text-pale);
}

.baggage_icon-1Y5-f {
	color: var(--brand-1-0);
}

.baggage_dialog_header_modal-13P9r {
	padding: 20px 32px;
	grid-gap: 10px;
	gap: 10px;
	align-self: stretch;

	border-radius: 20px 20px 0 0;

	background: var(--bg-white);
}

.baggage_dialog_header_modal_close-W6aIz {
	top: 8px;
	right: 8px;
}

.baggage_dialog_header_modal_close-W6aIz:hover svg rect {
	fill: var(--bg-elements);
}

.baggage_dialog_header_wrapper-3jz_k {
	border-radius: 4px;

	background: var(--bg-fill);
}

.baggage_dialog_header_category-1zUd1 {
	color: var(--brand-1-0);
	font-size: 18px;
	font-weight: 700;
	line-height: 130%;
}

.baggage_dialog_header_equipment-1uVpm {
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
}

.baggage_dialog_price_label-wecSU {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 700;
	line-height: 130%;
}

.baggage_dialog_description-NBk70 {
	color: var(--text-light);
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
}

.baggage_dialog_header_label-18OW4 {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 130%;
}

.baggage_dialog_body_segment-1B711 {
	display: flex;
	grid-gap: 0;
	gap: 0;
	align-items: flex-start;
	align-self: stretch;
	padding: 16px 8px 16px 12px;

	color: var(--text-base);

	background: var(--brand-1-5);
}

.baggage_dialog_body_passenger-NeNdT {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
}

.baggage_dialog_body_passenger_number-28sfc {
	color: var(--text-light);

	background: var(--brand-1-5);
}

span.passenger_checkbox-PqHxR {
	color: var(--text-pale);
}

span.passenger_checkbox-PqHxR.passenger_checkbox_checked-nsWwx {
	color: var(--brand-1-0);
}

.baggage_dialog_footer_price-wMiTk {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 130%;
}

.baggage_dialog_footer_clear-3Qovv {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 130%;
}

button.baggage_dialog_footer_confirm-2EZrM {
	display: inline-flex;
	grid-gap: 6px;
	gap: 6px;
	justify-content: center;
	align-items: center;
	padding: 10px 24px;

	border: none;
	border-radius: 4px;

	color: var(--bg-white);
	font-weight: 400;
	font-size: 14px;
	font-style: normal;
	line-height: 130%;

	background: var(--brand-3-0);
}

button.baggage_dialog_footer_confirm-2EZrM:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

button.baggage_dialog_footer_cancel-RNTQZ {
	display: inline-flex;
	grid-gap: 6px;
	gap: 6px;
	justify-content: center;
	align-items: center;
	padding: 10px 24px;

	border: 1px solid var(--brand-3-0);
	border-radius: 4px;

	color: var(--brand-3-0);
	font-weight: 400;
	font-size: 14px;
	font-style: normal;
	line-height: 130%;

	background: none;
}

button.baggage_dialog_footer_cancel-RNTQZ:hover {
	border: 1px solid var(--brand-3-0);

	color: var(--bg-white);

	background: var(--brand-3-0);
	box-shadow: none;
}

.baggage_dialog_footer-1U4Uz button.baggage_dialog_footer_cancel-RNTQZ,
.baggage_dialog_footer-1U4Uz button.baggage_dialog_footer_confirm-2EZrM {
	width: auto;
}

.equipment_wrapper-30nGj {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.equipment_wrapper-30nGj:has(.equipment_selected-2jFpY):not(.equipment_active-1Lfq8) {
	color: var(--bg-white);

	background-color: var(--brand-2-5);
}

.equipment_wrapper-30nGj.equipment_active-1Lfq8 {
	background-color: var(--brand-1-0);
}

.equipment_wrapper-30nGj.equipment_active-1Lfq8:has(.equipment_selected-2jFpY) {
	background-color: var(--success-icon);
}

.equipment_selected-2jFpY {
	background-color: var(--success-icon);
}

.direction-2_R2s {
	color: var(--success-text);
}

.flight_count-2O0xg {
	background-color: var(--success-icon);
}

.flight_icon-4Z8FN {
	background-color: var(--brand-1-0);
}

.root-Eygo5 .flight_count-2O0xg {
	background-color: var(--success-icon);
}

.root-Eygo5 .flight_icon-4Z8FN {
	background-color: var(--brand-1-0);
}

/* baggage icons */
.baggage_icon-1Y5-f .carryOn_small .carryOn__innerFill,
.baggage_icon-1Y5-f .carryOn_large .carryOn__innerFill,
.baggage_icon-1Y5-f .baggage_small .baggage__innerFill,
.baggage_icon-1Y5-f .baggage_middle .baggage__innerFill,
.baggage_icon-1Y5-f .baggage_large .baggage__innerFill {
	opacity: 1;

	fill: var(--brand-1-4);
}

.baggage_icon-1Y5-f .baggageExcess_small .baggageExcess__circleFill,
.baggage_icon-1Y5-f .baggageExcess_middle .baggageExcess__circleFill,
.baggage_icon-1Y5-f .baggageExcess_large .baggageExcess__circleFill {
	opacity: 0.5;

	fill: var(--brand-1-4);
}

.baggage_icon__root-3T2-T.baggageExcess-3DUwC {
	width: 70px;
}

.baggage_icon__root-3T2-T.baggageExcess-3DUwC .baggage_icon-1Y5-f .baggage_icon__size-2j-Jq {
	display: block;
}

.baggage_icon__prefix-GQSlb {
	margin-right: 4px;
}

@media screen and (max-width: 600px) {
	.icon-3zq9S {
		background: transparent;
	}

	.includedBaggage__root-22RzM {
		padding-bottom: 20px;
	}

	.includedBaggage__root-22RzM .slick-dots {
		bottom: -6px;
	}
}

.baggage_icon__root-3T2-T.baggageExcess-3DUwC .baggage_icon-1Y5-f {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.baggage_icon__root-3T2-T.baggageExcess-3DUwC .baggage_icon-1Y5-f .baggage_icon__size-2j-Jq .baggage_icon__value-2v99T:before {
	content: normal;
	content: initial;
}
