@media screen and (max-width: 600px) {
	.container-2H0Tt {
		display: flex;
		flex-direction: column;
		grid-gap: 10px;
		gap: 10px;

		margin-top: -30px;
	}
}
