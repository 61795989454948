

.selectable-1x4b9[data-rfisc='CMF'] > .seat-1lEaz {
	background-color: var(--seat-business);
}

.selectable-1x4b9[data-rfisc='0B5'] > .seat-1lEaz {
	background-color: var(--seat-economy-front);
}

.selectable-1x4b9[data-rfisc='SR1'] > .seat-1lEaz {
	background-color: var(--seat-standart);
}

.selectable-1x4b9[data-rfisc='STR'] > .seat-1lEaz {
	background: #e0c1f8;
}

.selectable-1x4b9[data-rfisc='PRS'] > .seat-1lEaz {
	background: #afcfff;
}

.selectable-1x4b9[data-rfisc='PSA'] > .seat-1lEaz {
	background: #cbcdfd;
}

.selectable-1x4b9[data-rfisc='SPE'] > .seat-1lEaz {
	background: #edc5ff;
}

.selectable-1x4b9[data-rfisc='ST7'] > .seat-1lEaz {
	background: #ffcac1;
}

.selectable-1x4b9[data-rfisc='SMR'] > .seat-1lEaz {
	background: #fee9a7;
}

.selectable-1x4b9[data-rfisc='SAS'] > .seat-1lEaz {
	background: #fdff95;
}

.selectable-1x4b9[data-rfisc='ST2'] > .seat-1lEaz {
	background: #dbf3c2;
}

.selectable-1x4b9[data-rfisc='FRF'] > .seat-1lEaz {
	background: #c8f2ff;
}

.selectable-1x4b9[data-rfisc='PFS'] > .seat-1lEaz {
	background: var(--seat-standart);
}

button.single-3jKsb {
	color: var(--bg-white);

	background: var(--brand-3-0);
}

button.single-3jKsb:hover {
	background: var(--brand-3-1);
}
