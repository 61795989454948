.wrapper-2ZJvb {
	height: 96%;
	margin-top: 20px;
}

.title-1gfA8 {
	margin-bottom: 0;
}

button.selectButton-1wkGq {
	box-shadow: 4px 7px 26px 0 rgba(0, 0, 0, 0.14);
}

@media screen and (max-width: 600px) {
	div.insuranceUpsale__modal-2CGwX {
		border-radius: 8px 8px 0 0;
	}
}
