

.wrapper-1_eje {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 90;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 5px 16px;

	background: var(--dark-orange);
}

button.button-3cGq4 {
	height: 36px;
	min-width: 0;
	padding: 5px 10px;

	line-height: 26px;

	font-family: inherit;
	color: #ffffff;
	text-transform: none;
}

button.button_back-16ZMk {
	font-weight: 400;
}

button.button_continue-2iim2 {
	margin-left: auto;
}
