

.price-37z-y {
	border-color: var(--line-separator);
}

.price_selected-1kYEu .fare_family-1qP-L {
	margin-bottom: 8px;
}

.price_selected-1kYEu .prices__wrp-3Wjx8 {
	display: flex;
	justify-content: center;
	align-items: center;

	min-height: 46px;

	padding: 0 16px;

	border-radius: 40px;

	color: var(--white);
	line-height: inherit;

	background: var(--brand-3-0);
}

.price__money-bRhYz {
	padding-left: 0;
}

.price-37z-y.price_selected-1kYEu .price__money-bRhYz {
	padding: 0;

	color: var(--white);
}

button.button-Gy5-B {
	line-height: inherit;

	background: var(--brand-3-0);
}

button.button-Gy5-B:hover {
	background: var(--brand-3--1);
}
