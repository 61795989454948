.searchForm__searchForm-6iln3 {
	background-color: var(--brand-1-0);
}

.value__value-1ftrP,
.value__code-VjuRk,
.datepicker__dates-3wTra,
.passengers__selector-3FN3b,
.currency__selector-1Jplq {
	font-size: 16px;
	font-weight: 500;
}

.value__value-1ftrP {
	color: var(--text-base);
}

.value__code-VjuRk {
	color: var(--text-light);
}

.passengers__passengers-11cg2 {
	border-right: 1px solid var(--line-separator);
}

.currency__currency-1VR3Z {
	margin-right: 0;
	flex-grow: 0;
}

.location__switcherHighlighted-1XzTv svg path {
	fill: var(--text-pale);
}

.datepicker__datesBack-3GP9I,
.datepicker__datesTo-3kGhC {
	border-bottom: 3px solid transparent;
}

.datepicker__datesBackFocused-3YAtB,
.datepicker__datesToFocused-2GPGT {
	border-bottom: 3px solid var(--accent-secondary);

	color: var(--text-base);
}

.datepicker__datesFocused-Bwu_K .datepicker__returnDelete-13lR4 {
	color: var(--text-pale);
}

.passengers__mobileTitle-1S1mA {
	font-size: 22px;
	font-weight: 700;
	color: var(--text-base);
}

.segment__remover-3Q_AU {
	width: 36px;
	height: 36px;
	margin-top: 7px;
	margin-right: -10px;

	border-radius: 100px;

	background: var(--brand-1-1);
}

.segment__remover-3Q_AU:before {
	content: '';

	width: 20px;
	height: 20px;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xOSAxM0g1VjExSDE5VjEzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==);
	background-position: center center;
	background-size: 100%;
}

.segment__remover-3Q_AU svg {
	display: none;
}

@media screen and (max-width: 600px) {
	.location__switcher-3oy6x.location__switcherAlternative-3XyI8 {
		background: var(--brand-1-0);
	}

	.searchForm__searchForm-6iln3 {
		background-color: var(--brand-3-4);
	}

	.value__code-VjuRk {
		color: var(--text-base);
		font-size: 20px;
		font-weight: 700;
	}

	.value__value-1ftrP {
		color: var(--text-mid);
		font-size: 13px;
		font-weight: 400;
	}

	.multiCity__caption-2b6JX {
		color: var(--text-base);
		font-size: 16px;
		font-weight: 400;
	}

	.bottom__controls-3ydTV,
	.multiCity__multiCity-3Mi16 {
		border-bottom: unset;
	}

	.datepicker__alternativeDow-33lsr {
		display: none;
	}

	.datepicker__dow-1Y8-I {
		opacity: 1;
	}

	.datepicker__alternative-3k-if .datepicker__dow-1Y8-I {
		display: block;
	}

	.datepicker__alternative-3k-if .datepicker__datesTo-3kGhC {
		font-size: 16px;
		font-weight: 500;
	}

	.datepicker__oneDate-3QgzY.datepicker__alternative-3k-if .datepicker__datesTo-3kGhC {
		font-size: 18px;
		font-weight: 700;
	}

	.datepicker__datesTo-3kGhC > .datepicker__datesToText-26JdI > span:nth-child(2) {
		margin-left: 5px;
	}

	.datepicker__datesToText-26JdI {
		display: flex;
	}

	.currency__item-3JbU8:hover {
		background: var(--text-accent);
	}

	button.actions__addSegmentAlternative-ODL3-,
	button.actions__removeSegmentAlternative-1Jlu6 {
		color: var(--text-pale);

		background-color: var(--white);
	}

	.value__withMultiCity-11Px5.value__wrapperAlternative-1P0p6 .value__code-VjuRk {
		font-size: 18px;
		font-weight: 700;
	}

	.value__wrapperAlternative-1P0p6 .value__value-1ftrP {
		font-size: 13px;
		font-weight: 400;
		color: var(--text-pale);
	}

	.passengers__selectorFocused-1LzP_ {
		color: var(--text-base);
	}

	.segment__alternative--1qyJ .segment__withMultiCity-1JINg {
		grid-gap: 1px;
		gap: 1px;
	}

	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellLocation-Yh5AI,
	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellDate-1Bx7_,
	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellLocation-Yh5AI .segment__input-H_rN8 {
		height: 61px;
	}

	.segment__alternative--1qyJ .segment__withMultiCity-1JINg .segment__cellLocation-Yh5AI .segment__input-H_rN8 {
		line-height: 61px;
	}

	.controls__controls-3ZZ19.controls__controlsWithCurrency-enRxz {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 4px;
	}

	.startSearch__startSearch-20M5y,
	.startSearch__startSearch-20M5y.startSearch__startSearchIconMode-10rMC {
		grid-area: 2 / 1 / 3 / 3;
	}

	.passengers__passengers-11cg2 {
		border: 0;
	}

	.currency__selector-1Jplq {
		height: 100%;
	}
}
