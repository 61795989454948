

.fields-1aVKf {
	flex: 1;
	overflow: auto;
}

.controls-fPpvf {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr 1fr;
	height: 65px;
	padding: 12px;

	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
	background: #ffffff;
}

button.cancel-1ytwK,
button.cancel-1ytwK:hover {
	border-radius: 4px;

	color: var(--text-light);
	font-weight: 400;

	background: var(--bg-fill);
}

button.confirm-24mjy,
button.confirm-24mjy:hover {
	background: var(--brand-3-0);
}

button.confirm-24mjy.disabled-3px-m {
	background: var(--brand-3-3);
}
