

.seat-cV4jv.seat_preferred-3J18S .title-2-keH:before {
	background: var(--seat-front);
}

.seat-cV4jv[data-rfisc='CMF'] > .header-UAUtZ > .title-2-keH:before {
	background-color: var(--seat-business);
}

.seat-cV4jv[data-rfisc='0B5'] > .header-UAUtZ > .title-2-keH:before {
	background-color: var(--seat-economy-front);
}

.seat-cV4jv[data-rfisc='SR1'] > .header-UAUtZ > .title-2-keH:before {
	background-color: var(--seat-standart);
}

.seat-cV4jv[data-rfisc='STR'] > .header-UAUtZ > .title-2-keH:before {
	background: #e0c1f8;
}

.seat-cV4jv[data-rfisc='PRS'] > .header-UAUtZ > .title-2-keH:before {
	background: #afcfff;
}

.seat-cV4jv[data-rfisc='PSA'] > .header-UAUtZ > .title-2-keH:before {
	background: #cbcdfd;
}

.seat-cV4jv[data-rfisc='SPE'] > .header-UAUtZ > .title-2-keH:before {
	background: #edc5ff;
}

.seat-cV4jv[data-rfisc='ST7'] > .header-UAUtZ > .title-2-keH:before {
	background: #ffcac1;
}

.seat-cV4jv[data-rfisc='SMR'] > .header-UAUtZ > .title-2-keH:before {
	background: #fee9a7;
}

.seat-cV4jv[data-rfisc='SAS'] > .header-UAUtZ > .title-2-keH:before {
	background: #fdff95;
}

.seat-cV4jv[data-rfisc='ST2'] > .header-UAUtZ > .title-2-keH:before {
	background: #dbf3c2;
}

.seat-cV4jv[data-rfisc='FRF'] > .header-UAUtZ > .title-2-keH:before {
	background: #c8f2ff;
}

.seat-cV4jv[data-rfisc='PFS'] > .header-UAUtZ > .title-2-keH:before {
	background: var(--seat-standart);
}

.seat__info-FILgO {
	border-color: var(--line-separator);
}

.features-1Fc4R {
	color: var(--text-light);
	font-size: 14px;
}

.price__wrapper-JQQal {
	color: var(--text-base);
}

.price-2mBI2 {
	font-size: 14px;
}

.description-vOXyI {
	color: var(--text-mid);
	font-size: 14px;
}

.additionalInfo-2J46Z ul {
	list-style-type: disc;
}
