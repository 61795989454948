.currency-SX15i {
	position: relative;

	flex-grow: 1;
	min-width: 112px;
}

.selector-31Lnu {
	display: inline-flex;
	align-items: center;

	cursor: pointer;
	overflow: hidden;
}

.selector__inner-3pkdE span:first-of-type:after {
	content: ' - ';
}

.dropdown-3Dv9E {
	position: absolute;

	z-index: 10;

	width: 100%;
	margin-top: 2px;
	padding: 4px;

	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 15px;

	background: #ffffff;
}

.item-2E9hJ {
	padding: 12px;

	cursor: pointer;

	transition: background-color 0.2s;
}

.item-2E9hJ:hover {
	background: var(--brand1-5);
}

.arrowIcon-3Z8hC {
	position: absolute;
	right: 12px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(12px * 2);
	height: calc(12px * 2);

	color: #ffffff;

	cursor: pointer;

	transition: transform 0.15s;

	pointer-events: none;
}

.arrowIcon_flipped-1HuEC {
	transform: rotate(180deg);
}

@media screen and (max-width: 600px) {
	.dropdown-3Dv9E {
		position: relative;
	}

	.selector-31Lnu {
		justify-content: center;

		font-size: 16px;
	}

	.selector-31Lnu:focus {
		color: var(--text-base);
	}

	.currency-SX15i {
		margin-right: 0;
		margin-top: 4px;
	}
}
