

.flight-1-H3h {
	border-radius: 4px;

	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.flight-1-H3h:not(.selected-3XzJH):hover {
	box-shadow: 4px 4px 33px rgba(0, 0, 0, 0.05);
}

.dropdown__icon-3biBk {
	display: none;
}
