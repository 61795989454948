

.wrapper-9QZLJ {
	padding-top: 0;
}

.item-21dIn {
	min-height: 96px;
}

.item-21dIn:first-child {
	border-radius: 4px 0 0 4px;
}

.item-21dIn:last-child {
	border-radius: 0 4px 4px 0;
}

.item__head-2UKrx span,
.item__middle-1Vnej span {
	border-radius: 20px;
}
