

div.fareGroup_recommended-1wHHi:not(.selected-2s_Dr) {
	height: calc(100% - 16px);
	margin-top: 8px;

	box-shadow: 0 0 0 2px var(--fareGroup-recommended-border-color);
}

.fareGroup_white-OTgji {
	border-radius: 4px;
}

.fareGroup_white-OTgji .option__title-2FVfm {
	color: var(--text-mid);

	white-space: normal;
}

.fareGroup_white-OTgji .option__value-2Eugw {
	color: var(--text-mid);
}

.fareGroup_white-OTgji .option__value-2Eugw .icon_included {
	color: var(--success-icon);
}

.fareGroup_white-OTgji .option-3R9Pv {
	padding: 6px 0;
}

.fareGroup_white-OTgji .option-3R9Pv:not(:last-of-type) {
	border-bottom: 1px dashed var(--line-separator);
}

.options-1dCj5 {
	margin-bottom: 12px;
}

button.button-2Uaea:disabled {
	background: var(--seat-unavailable);
}

.keyFeature-2oVPM {
	border-radius: 4px;
}

.fareGroup_white-OTgji.fareGroup_Business-1RIba .keyFeature-2oVPM {
	background: var(--brand-1-0);
}

.price-2gjU7 {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.1;
}
