

.item-3i3VR {
	font-weight: 500;
}

.item-3i3VR.item_selected-1I0LF {
	position: relative;

	padding: 11px 25px 11px 14px;

	color: var(--white);

	background: var(--brand-1-0);
}

.item-3i3VR.item_selected-1I0LF:after {
	position: absolute;
	right: 5px;

	width: 21px;
	height: 21px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iVW5pb24iIGQ9Ik0xNC4yMTIzIDE1LjQ0OTNMMTAuNSAxMS43MzdMNi43ODc2NyAxNS40NDkzTDUuNTUwMjMgMTQuMjExOUw5LjI2MjU0IDEwLjQ5OTZMNS41NTAyMyA2Ljc4NzI0TDYuNzg3NjcgNS41NDk4TDEwLjUgOS4yNjIxMkwxNC4yMTIzIDUuNTQ5OEwxNS40NDk3IDYuNzg3MjRMMTEuNzM3NCAxMC40OTk2TDE1LjQ0OTcgMTQuMjExOUwxNC4yMTIzIDE1LjQ0OTNaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=) no-repeat center / contain;
	content: '';
}

.item-3i3VR.item_selected-1I0LF .item__icon-33Khq {
	display: none;
}
