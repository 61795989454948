

.inner-2Sq1U {
	border-bottom: none;

	box-shadow: none;
}

.form-3ArrZ {
	background: var(--quickSearchForm-background);
}

.form-3ArrZ .searchForm-1hT_m {
	background: transparent;
}

.form-3ArrZ .multiCity__caption-3QO6d {
	color: var(--brand-1-0);

	opacity: 1;
}

.form-3ArrZ .segment__remover-3kFkX {
	color: var(--brand-1-0);
}

.form-3ArrZ button.addSegment-ym3oU {
	color: var(--white);

	background: rgba(22, 24, 52, 0.2);
}

.form-3ArrZ button.addSegment-ym3oU:hover {
	color: var(--white);

	background: var(--brand-1-0);
}
