

.element-3rsdy {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 48px;

	border-color: var(--brand-3-0);

	color: var(--brand-3-0);

	background: var(--brand-3-4);
}

.element-3rsdy svg path {
	fill: var(--brand-3-0);
}

.element-3rsdy.element_active-otiGk {
	border-color: var(--brand-3-0);

	color: var(--white);

	background: var(--brand-3-0);
}

.element-3rsdy.element_active-otiGk svg path {
	fill: var(--white);
}

.element-3rsdy.element_error-2B8Kp {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.element-3rsdy.element_error-2B8Kp svg path {
	fill: var(--error-icon);
}

.element-3rsdy:first-of-type {
	border-radius: 40px 0 0 40px;
}

.element-3rsdy:last-of-type {
	border-radius: 0 40px 40px 0;
}

.element-3rsdy svg {
	position: relative;
	top: 0;
	left: 0;
	right: 0;

	pointer-events: none;
}

.element-3rsdy:last-of-type svg {
	left: 0;
}

.error-1Dfy6 {
	max-width: 115px;
}
