

button.root-1LfbW {
	padding-right: 24px;
	padding-left: 24px;

	font-weight: 400;
	line-height: 1.3;
	text-transform: none;
}

span.endIcon-2rAg7 {
	margin-left: 4px;
}

button.secondary-2jRm2,
button.action-2zBPb {
	min-height: 52px;
	padding: 12px 24px;
}

/* Secondary / SmallSecondary */
button.smallSecondary-1T5Jz,
button.smallPrimary-3zmwj,
button.smallNeitral-3lIYl,
button.smallThird-3I8Kz {
	min-width: 90px;
	min-height: 39px;
	padding-top: 10px;
	padding-bottom: 10px;

	border-radius: var(--btn-small-brds);

	font: var(--normal-desktop-bold);
}

button.secondary-2jRm2 {
	min-width: 120px;

	border-radius: var(--btn-secondary-border-radius);

	font: var(--medium-desktop);
}

button.secondary-2jRm2,
button.smallSecondary-1T5Jz {
	color: var(--text-light);

	background: var(--bg-fill);
}

button.secondary-2jRm2:hover,
button.smallSecondary-1T5Jz:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

button.secondary-2jRm2.disabled-10GZ5,
button.smallSecondary-1T5Jz.disabled-10GZ5 {
	color: var(--line-dot-line);

	background: var(--bg-fill);
}

/* Action / SmallPrimary  */
button.action-2zBPb {
	min-width: 120px;

	border-radius: var(--btn-action-border-radius);

	font: var(--medium-desktop-bold);
}

button.action-2zBPb,
button.smallPrimary-3zmwj {
	color: var(--white);
	font-weight: 700;

	background: var(--brand-3-0);
}

button.action-2zBPb:hover,
button.smallPrimary-3zmwj:hover {
	color: var(--white);

	background: var(--brand-3-1);
}

button.action-2zBPb.disabled-10GZ5,
button.smallPrimary-3zmwj.disabled-10GZ5 {
	color: var(--white);

	background: var(--brand-3-3);
}

button.action-2zBPb.disabled-10GZ5.loading-2B3Xk,
button.smallPrimary-3zmwj.disabled-10GZ5.loading-2B3Xk {
	background: var(--bg-fill);
}

/* SmallNeitral */
button.smallNeitral-3lIYl {
	color: var(--brand-1-0);
	font-weight: 700;

	background: var(--brand-1-5);
}

button.smallNeitral-3lIYl:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

button.smallNeitral-3lIYl.disabled-10GZ5 {
	color: var(--text-pale);

	background: var(--bg-fill);
}

/* smallThird */
button.smallThird-3I8Kz {
	color: var(--white);

	background: var(--brand-1-0);
}

button.smallThird-3I8Kz:hover {
	background-color: var(--brand-1-1);
}

button.smallThird-3I8Kz.disabled-10GZ5 {
	color: var(--text-pale);

	background: var(--bg-fill);
}

/* OptionEdit */
button.optionEdit-2mgIV,
button.optionAction-3YUv1,
button.optionActionPlusIcon-31Os4 {
	min-width: 90px;
	min-height: 34px;
	padding: 8px 16px;

	border-radius: 40px;

	font: var(--normal-desktop-accent);
}

button.optionEdit-2mgIV {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--white);
}

button.optionEdit-2mgIV:hover {
	border-color: var(--brand-2-4);

	color: var(--brand-1-0);

	background: var(--brand-2-4);
}

button.optionEdit-2mgIV.disabled-10GZ5 {
	border-color: var(--bg-elements);

	color: var(--text-pale);

	background: var(--bg-elements);
}

/* OptionAction */
button.optionAction-3YUv1,
button.optionActionPlusIcon-31Os4 {
	color: var(--white);

	background: var(--brand-1-0);
}

/* OptionActionPlusIcon */
button.optionActionPlusIcon-31Os4 {
	padding-top: 4px;
	padding-bottom: 4px;
}

button span.startIcon-3K_dk svg,
button.optionActionPlusIcon-31Os4 span.endIcon-2rAg7 svg {
	width: 20px;
	height: 20px;
}

button.optionAction-3YUv1:hover,
button.optionActionPlusIcon-31Os4:hover {
	color: var(--white);

	background: var(--brand-1--1);
}

button.optionAction-3YUv1.disabled-10GZ5,
button.optionActionPlusIcon-31Os4.disabled-10GZ5 {
	color: var(--text-pale);

	background: var(--bg-elements);
}

/* text */
button.text-kCCb4 {
	min-width: 0;
	height: auto;
	padding: 4px 8px;

	border-radius: 4px;

	color: var(--common-blue);
	line-height: normal;
	font-size: 15px;

	background-color: transparent;
}

button.text-kCCb4:hover {
	background-color: transparent;
}

button.loading-2B3Xk,
button.loading-2B3Xk:hover {
	border-color: transparent;

	background: var(--bg-fill);
}
