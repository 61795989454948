.wrapper-mzwuD {
	display: flex;
	align-items: center;
}

.wrapper__inner-14DBq {
	flex: 1;
	display: flex;
	align-items: center;
}

.image-11spA {
	width: 40px;
	height: 40px;
	margin-right: 14px;
}

.icon-3sql9 {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-right: 14px;

	color: var(--gray50);
}

.content-1XIRD {
	display: flex;
	flex-direction: column;

	overflow-wrap: break-word;

	color: var(--gray70);
}

.title-2H_Gv {
	margin-bottom: 2px;

	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.title-2H_Gv:only-child {
	margin-bottom: 0;
}

.wrapper-mzwuD.context-1majU {
	padding: 12px;

	border: 1px solid #add3ff;
	border-radius: 4px;

	background-color: var(--base90);
}

.wrapper-mzwuD.global-sdTJR {
	padding: 18px;
	margin-top: 12px;

	background-color: var(--white);
}

.context-1majU.info-1mJFJ,
div.global-sdTJR.info-1mJFJ {
	background-color: #fffdcc;
}

.context-1majU.info-1mJFJ {
	border: 1px solid #efea6c;
}

.context-1majU.success-2DlZ4 {
	border: 1px solid var(--success60);

	background-color: #e7f7e3;
}

div.global-sdTJR.success-2DlZ4 {
	background-color: var(--success);
}

.context-1majU.warning-3UPu6 {
	border: 1px solid var(--error-stroke-hover);

	background-color: var(--error-bg);
}

div.global-sdTJR.warning-3UPu6 {
	background-color: var(--error);
}

.context-1majU .text-1Qb8g {
	font-size: 14px;
	line-height: 1.3;
}

.context-1majU .text-1Qb8g p,
.global-sdTJR .text-1Qb8g p {
	margin: 0;
}

.context-1majU .text-1Qb8g a,
.global-sdTJR .text-1Qb8g a {
	color: var(--base);
	text-decoration: underline;
}

.global-sdTJR .text-1Qb8g {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
}

.context-1majU.warning-3UPu6 .title-2H_Gv,
.context-1majU.success-2DlZ4 .text-1Qb8g {
	color: var(--success-text);
}

.context-1majU.success-2DlZ4 .icon-3sql9 {
	color: var(--success-icon);
}

.context-1majU.warning-3UPu6 .title-2H_Gv,
.context-1majU.warning-3UPu6 .text-1Qb8g {
	color: var(--error-text);
}

.context-1majU.warning-3UPu6 .icon-3sql9 {
	color: var(--error-icon);
}

.global-sdTJR.success-2DlZ4 .content-1XIRD,
.global-sdTJR.success-2DlZ4 .icon-3sql9,
.global-sdTJR.warning-3UPu6 .content-1XIRD,
.global-sdTJR.warning-3UPu6 .icon-3sql9 {
	color: var(--white);
}

button.action-3eUom {
	margin-left: auto;
}

button.action-3eUom:hover {
	border: 1px solid #add3ff;

	background-color: var(--gray05);
}

button.action-3eUom > svg {
	margin-left: 6px;
}

@media screen and (max-width: 600px) {
	.wrapper-mzwuD {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 14px;
		gap: 14px;
	}

	.wrapper-mzwuD.global-sdTJR {
		border: 1px solid #add3ff;
		border-radius: 4px;

		background-color: var(--base90);
	}

	div.global-sdTJR:not(.info-1mJFJ, .success-2DlZ4, .warning-3UPu6) .icon-3sql9 {
		color: var(--selected);
	}

	div.global-sdTJR.info-1mJFJ {
		border: 1px solid #efea6c;
	}

	button.action-3eUom {
		margin-left: 0;
	}
}
