.wrapper-2huOO {
	padding: 20px 20px 24px;

	box-shadow: none;
	background: var(--white);
}

.formWrapper-3k9J1 {
	padding: 0;
}

.addButton-1BZzs {
	margin: 0;

	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.addButtonIcon-23Qd- {
	position: relative;

	flex-shrink: 0;
	width: 32px;
	height: 32px;
	margin-right: 8px;

	border-radius: 50%;

	background: var(--brand-1-0);
}

.addButtonIcon-23Qd-:before,
.addButtonIcon-23Qd-:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 2px;

	background: var(--white);
	transform: translate(-50%, -50%);
}

.addButtonIcon-23Qd-:after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.addButtonIcon-23Qd- > svg {
	display: none;
}

.form-19DYt .submitButton-3Pu7f {
	min-width: unset;
	height: 30px;

	padding: 2px 16px 0;

	font-weight: 700;

	font-size: 14px;

	background: var(--brand-1-0);
}

.form-19DYt .submitButton-3Pu7f:hover {
	background: var(--brand-1--1);
}

.passengerIcon-3BfVp {
	display: none;
}

div.form__input-3Y7nX {
	transform: none;
}

@media screen and (max-width: 600px) {
	.wrapper-2huOO:last-of-type {
		border-radius: 0;
	}
}
