

.container-2nF7w {
	padding: 32px;

	border-radius: 20px;

	border: none;
}

.container-2nF7w,
.container-2nF7w:hover {
	box-shadow: none;
}

.title-3OrVB {
	margin-bottom: 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

.title__icon-3TDvp {
	display: none;
}

.button-3J_bE {
	background: var(--brand-3-0);
}

button.button-3J_bE {
	min-height: 48px;
	padding: 2px 24px 0;

	border-radius: 6px;

	color: var(--white);
	font-size: 18px;
	font-weight: 700;

	background: var(--brand-3-0);
}

button.button-3J_bE:hover {
	color: var(--white);

	background: var(--brand-3-0);
}

button.button-3J_bE.button_disabled-yOpaK {
	color: var(--white);

	background: var(--brand-3-0);

	opacity: 0.8;
}

.container-2nF7w .notification__text-19lQJ {
	font-weight: 500;
	line-height: 1.1;
}

.form__row-1HLGs {
	margin-top: 24px;
}
