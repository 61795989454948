

.graphHeader-FL9Kw:after {
	left: 62px;
}

div.calendar_longPrice-3mGgt {
	padding-left: 90px;
}

div.graphHeader_longPrice-_Bo-X:after {
	left: 92px;
}
