.time-3AMbz {
	color: var(--text-base);
	font-size: 56px;
	font-weight: 700;
	line-height: 1.3;
}

.airport-1R4Jq {
	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.point_thin-hfKRS .time-3AMbz {
	color: var(--text-pale);
}

@media screen and (max-width: 600px) {
	.airport-1R4Jq {
		font-size: 13px;
		font-weight: 400;
	}

	.date__container-1M8Bs,
	.point_arrival-3kBm1 .date__container-1M8Bs {
		align-items: center;
	}
}
