.compareFaresLink-RiaFX {
	margin-top: -4px;

	color: var(--brand-1-0);
	font-weight: 600;
	font-size: 16px;
}

.compareFaresLink_icon-1DD7f {
	display: none;
}

button.fareGroup__button-2QlqZ {
	min-height: 44px;

	padding: 0 16px;

	border: none;
	border-radius: 40px;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	line-height: 44px;

	transition: color 0.15s, background 0.15s;
}

button.fareGroup__button-2QlqZ:hover {
	color: var(--text-base);

	background: var(--white);
}

button.fareGroup__button_active-zpktf,
button.fareGroup__button_active-zpktf:hover {
	color: var(--white);

	background: var(--brand-1-0);
}

button.scroll_up-3tsiZ {
	border-radius: 4px;
}

.pagination__index-3Z9Ne {
	color: var(--text-light);
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
}

.paper-pA7HZ .slick-prev {
	left: 44%;
}

.paper-pA7HZ .slick-next {
	right: 44%;
}

@media (min-width: 1025px) {
	div.paper-pA7HZ {
		padding: 12px 16px 16px;

		border-radius: 4px;

		background: var(--brand-1-5);
	}
}

@media screen and (max-width: 600px) {
	.buttons-2UMID {
		grid-gap: 8px;
		gap: 8px;
	}

	.buttons-2UMID button.fareGroup__button-2QlqZ {
		margin: 0;
	}

	.compareFaresLink-RiaFX {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 100%;

		margin-top: 8px;

		text-align: center;
	}
}
