.wrapper-MFuZT {
	padding: 20px;

	border-radius: 0 0 4px 4px;

	background: var(--white);
}

button.addButton-36q9N {
	width: 100%;
}

@media (min-width: 1024px) {
	button.addButton-36q9N {
		width: auto;

		margin-left: -6px;
		padding: 6px 10px;

		letter-spacing: normal;

		background: transparent;
	}

	button.addButton-36q9N:hover {
		background: transparent;
	}
}

.addButton__icon--wjqX {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	padding: 0;

	border-radius: 50%;

	color: var(--white);

	background: var(--brand-1-0);
}

button.addButton-36q9N .addButton__icon--wjqX svg {
	width: 24px;
	height: 24px;
}

.form__wrapper-d079P {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.form-31Y-y {
	flex: 1;
	display: flex;
	align-items: flex-start;
	grid-gap: 20px;
	gap: 20px;

	width: 100%;
}

.fields-V8oKD {
	flex: 1;
	display: flex;
	grid-gap: 20px;
	gap: 20px;
}

.form__input-26049 {
	flex: 1;
}

button.submitButton-1vP9e {
	margin-top: 10px;
}

.form__title-37pQi {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	font: var(--medium-1-desktop-bold);
}

.passengerIcon-kc57r {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 16px;
}

button.conditionButton-1p0kB {
	margin-left: -6px;

	color: var(--text-light);
}

.notification-29Euq.notification_context-2SAIu .notification__text-2ngXk {
	font: var(--medium--1-desktop-accent);
}

@media screen and (max-width: 600px) {
	.wrapper-MFuZT {
		position: relative;
	}

	.fields-V8oKD {
		flex-direction: column;
		grid-gap: 24px;
		gap: 24px;
	}

	.form__wrapper-d079P {
		flex-direction: column;
		align-items: normal;

		grid-gap: 20px;

		gap: 20px;
	}

	.form-31Y-y {
		flex-direction: column;
		align-items: normal;
		grid-gap: 36px;
		gap: 36px;
	}

	.form__title-37pQi {
		padding-bottom: 10px;
	}

	.addButton-36q9N {
		justify-content: center;

		min-height: 40px;
		margin: 0;

		border-radius: 4px;

		color: var(--brand-1-0);
		font-weight: 700;
		font-size: 14px;

		background: var(--brand-1-4);
	}

	.addButton__icon--wjqX {
		display: none;
	}

	button.submitButton-1vP9e {
		margin-top: 0;
	}
}
