

.root-3tc73 button.minus-2QKtb,
.root-3tc73 button.plus-t4g8E {
	width: 26px;
	min-width: 26px;
	height: 26px;
	min-height: 26px;

	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.root-3tc73 button.minus-2QKtb:not(.button_disabled-8mTFl):hover,
.root-3tc73 button.plus-t4g8E:not(.button_disabled-8mTFl):hover {
	color: var(--white);

	background: var(--brand-1-0);
}

.root-3tc73 button.button_disabled-8mTFl {
	color: var(--line-dot-line);
}

button.minus-2QKtb svg,
button.plus-t4g8E svg {
	width: 18px;
	height: 18px;
}
