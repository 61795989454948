

.container-33g2n {
	display: flex;
	flex-direction: column;

	width: 100%;

	margin: 0 auto;
	padding: 22px 13px;

	border-bottom: 1px dashed var(--cart-service-border);

	counter-increment: cart-counter;
}

.disabled-1jAY3 {
	opacity: 0.6;

	pointer-events: none;
}

.header-1RK67 {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-1RK67 svg {
	color: var(--bg-white);
}

.header__title-1YZlB,
.header__price-1Qt7U {
	color: var(--bg-white);
	font-weight: 700;
	font-size: 18px;
}

.header__title-1YZlB {
	flex: 1;
}

.header__price-1Qt7U {
	margin-left: auto;
}

.header__icon-196yB {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
}

.header__icon-196yB svg {
	width: 100%;
	height: 100%;
}

.header__icon-196yB,
.header__number-3U81r {
	flex-shrink: 0;
	margin-right: 12px;
}

.header__number-3U81r {
	position: relative;

	display: none; /* hidden by default, may be used in custom themes */
	width: 28px;
	height: 28px;

	border-radius: 50%;

	color: #ffffff;
	font-weight: 500;
	font-size: 13px;
	line-height: 28px;
	text-align: center;

	background: var(--brand-1--1);
}

.header__number-3U81r:before {
	content: counter(cart-counter);

	color: currentColor;
}

.content-3w-RW {
	display: flex;
	flex-direction: column;

	margin-top: 12px;
	padding: 12px;

	border-radius: 4px;

	background: var(--bg-white);
}

.footer-3Ght1 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

button.button-13dJy {
	display: flex;
}

button.button_add-371aC {
	color: var(--white);
}
