.controls_multiCity-RmEXu {
	width: 100%;
	justify-content: flex-start;
}

.controls_multiCity-RmEXu div {
	flex: 1;
}

.controls_multiCity-RmEXu div:last-of-type {
	margin-left: 4px;
}

.controls_multiCity-RmEXu div:last-of-type button {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.controls_multiCity-RmEXu div:last-of-type {
		margin-left: 0;
	}
}
