.form-3rgOv form {
	display: flex;
	flex-direction: column;
}

.container-2Zezf {
	padding-bottom: 60px;
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.container-2Zezf {
		padding-bottom: 64px;
	}
}

.contacts-3C9ld {
	border-radius: 4px;
}

.contacts-3C9ld,
.contacts-3C9ld:hover {
	box-shadow: none;
}

.form-3rgOv .contacts-3C9ld {
	order: 1;
}

.form-3rgOv .passengers-1yw9s {
	margin-top: 0;
}

.contacts__form-3a3-a {
	margin-bottom: 32px;
}

span.consents__checkbox-1zIDd.consents__checkbox_checked-1Z3iW {
	color: var(--brand-1-0);
}

.stepbar__additional-2Pwrh {
	color: var(--brand-1-0);
}

span.passengersToolbar__label-2yWuD {
	font-size: 14px;
	line-height: 1.3;
}

.passengersToolbar__label-2yWuD span {
	white-space: break-spaces;
}

.passengersToolbar__label-2yWuD a {
	color: var(--brand-3-0);
	text-decoration: none;
}

.gdsTotalPrice-3n4MJ {
	color: var(--text-base);
	font-weight: 400;
}

@media screen and (max-width: 600px) {
	.orderMessages-1XTGa {
		margin-bottom: 44px;
	}
}
