.header-IzJ_Q {
	box-shadow: none;
}

.header__stepbar-36rUq {
	border: none;
}

.inner-1D6ql {
	display: flex;
	flex-direction: column;

	border-radius: 0;

	background: var(--bg-fill);
}

button.toolbar__backButton-1PmUY {
	grid-gap: 10px;
	gap: 10px;

	min-width: auto;
	padding: 0;

	color: var(--text-pale);
	font-weight: 500;
	font-size: 16px;

	background: transparent;

	transition: color 0.15s;
}

button.toolbar__backButton-1PmUY > span {
	display: none;
}

button.toolbar__backButton-1PmUY:hover {
	color: var(--text-mid);

	background: transparent;
}

button.toolbar__backButton-1PmUY:before {
	width: 40px;
	height: 40px;

	border-radius: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjQjNCM0IzIi8+Cjwvc3ZnPgo=) no-repeat center / 60%, var(--brand-1-5);

	transition: background 0.15s;
	content: '';
}

button.toolbar__backButton-1PmUY:hover:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjRjY5NTVFIi8+Cjwvc3ZnPgo=) no-repeat center / 60%, var(--bg-fill);
}

.outer_bottom-3H-mo {
	position: relative;

	padding-left: 28px;
	margin-top: 0;
}

.outer_bottom-3H-mo:has(> button):before {
	position: absolute;
	top: 50%;
	left: 0;

	width: 20px;
	height: 20px;
	margin-top: -2px;

	transform: translateY(-50%);
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTEzIDExVjE3SDExVjExSDEzWk0xMiAyMEM3LjU4OTk5IDIwIDMuOTk5OTkgMTYuNDEgMy45OTk5OSAxMkMzLjk5OTk5IDcuNTkgNy41ODk5OSA0IDEyIDRDMTYuNDEgNCAyMCA3LjU5IDIwIDEyQzIwIDE2LjQxIDE2LjQxIDIwIDEyIDIwWk0xMyA3VjlIMTFWN0gxM1oiIGZpbGw9IiNiM2IzYjMiLz4KPC9zdmc+Cg==) no-repeat center / contain;
	content: '';
}

.selectedFares__wrapper-Ekosm {
	z-index: 1;
}

.messages-30RlQ {
	margin-bottom: 0;
}

@media (min-width: 1024px) {
	.results-33xJJ .flightList-1tH5l {
		order: 1;
	}

	.selectedFares__wrapper-Ekosm {
		order: -1;
	}

	.messages-30RlQ {
		order: -1;
		margin-top: 28px;
	}

	.selectedFares__wrapper-Ekosm ~ .messages-30RlQ {
		margin-top: 0;
	}
}

@media (max-width: 1024px) {
	.messages-30RlQ {
		margin-top: 28px;
	}
}

@media screen and (max-width: 600px) {
	.outer_bottom-3H-mo {
		padding-left: 0;
		margin-top: 8px;
	}

	.messages-30RlQ {
		margin-top: 0;
	}
}
