

.context-3SXCy.info-2fEdw {
	padding: 16px;

	border-color: var(--brand-3-0);

	background: var(--bg-fill);
}

.icon-2-NBv {
	color: var(--text-pale);
}

.content-3svJU {
	grid-gap: 2px;
	gap: 2px;

	color: var(--text-mid);
}

.title-3FW4C {
	margin: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.info-2fEdw .icon-2-NBv {
	color: var(--brand-3-0);
}

.info-2fEdw .text-2bwQZ {
	line-height: 1.5;
}
