.loyaltyConnect-2B1tX {
	padding: 0;
	flex-direction: column;
	grid-gap: 20px;
	gap: 20px;
}

.block__header__valid-28wdZ {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;

	border-radius: 24px;

	color: var(--success-icon);
}

.block__header__invalid-1cDX1 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;

	border-radius: 24px;

	color: var(--error-icon);

	cursor: pointer;
}

.block__header__invalid-1cDX1 svg {
	width: inherit;
	height: inherit;
}

.createTitle-TkZBE {
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
	align-items: center;
	justify-content: flex-start;
}

.createTitle-TkZBE > div {
	display: flex;
	align-items: center;
}

.header-DubpV {
	color: var(--text-base);
	font-size: 28px;
	font-weight: 600;
	line-height: 1.3;
}

.form__wrapper-Xht0l {
	display: flex;
	margin-top: 40px;
}

.form__content-1ZxnC {
	display: flex;
	grid-gap: 30px;
	gap: 30px;
	flex-direction: column;
	width: 100%;
	padding: 24px 0;
}

.block-2gYPP {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.block__headerWrapper-7J2Vv {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	align-self: stretch;
}

.block__header-2dUGW {
	display: flex;
	padding: 12px;
	justify-content: space-between;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
	align-self: stretch;

	border-radius: 4px;

	background: var(--bg-fill);
}

.block__header__titleWrapper-2kVjS {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 12px;
	gap: 12px;
}

.block__header__titleNumber-2Xg9L {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 32px;

	color: var(--text-mid);
	font: var(--medium--1-desktop-bold);

	background: var(--bg-white);
}

.block__header__title-SwK09 {
	color: var(--text-base);
	font: var(--medium-desktop-accent);
}

.block__header__subtitle-3N8UP {
	width: 100%;

	color: var(--text-mid);
	font: var(--medium--1-normal);
}

.groupCreate-9ZA3B {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
}

.groupCreate__field-1Jkhd > div {
	width: 100%;
}

.groupCreate__field-1Jkhd {
	position: relative;

	display: flex;
	width: 100%;
	margin-bottom: 0;
	align-self: stretch;
	align-items: center;
	flex-grow: 1;
}

.groupCreate__field-1Jkhd.groupCreate__field_switch-1aHdZ {
	display: flex;
	flex: 0;
	align-items: center;
}

.groupCreate-9ZA3B .groupCreate__field-1Jkhd p {
	position: absolute;
	bottom: -18px;
	left: 0;

	margin: 0;
	padding: 0;

	width: auto;

	transform: translateY(7%);
}

.group-YXJJ2 {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
}

.group__field-2a7gC {
	min-width: 50%;
	margin-bottom: 8px;
	align-self: stretch;
}

.group__field-2a7gC:only-child {
	max-width: 100%;
}

.group-YXJJ2 .group__field-2a7gC:not(:last-child) {
	padding-right: 20px;
}

.group-YXJJ2 .group__field-2a7gC p {
	width: auto;

	margin-left: 0;
	margin-right: 0;
}

.group__field-2a7gC > div {
	width: 100%;
}

.group__field-2a7gC.group__field_switch-1Y2Pp {
	flex: 0;
	min-width: 120px;
}

.buttons__wrapper-36cfg {
	display: flex;
	margin-top: 20px;
	align-items: center;
	justify-content: space-between;
}

.buttons__wrapper-36cfg a {
	text-decoration: none;
	font-size: 15px;
}

.actions-1zIns {
	display: flex;
	flex-direction: column;
}

.actions-1zIns a:not(:last-child) {
	margin-bottom: 10px;
}

.buttons__action-1UaRy {
	display: flex;
	flex-shrink: 0;
	align-items: center;

	color: var(--gray30);
	font-size: 15px;
}

.buttons__action-1UaRy .actions__success-2N1i8 {
	margin-right: 15px;
}

.errors-1RpUj {
	margin: 20px 0;

	color: var(--error);
	font-size: 14px;
}

.success-3l6ri {
	margin-top: 40px;

	color: #333333;
	font-size: 16px;
}

.success-3l6ri .buttons__wrapper-36cfg {
	justify-content: flex-end;
}

div.warning__modal-3J15B {
	z-index: 1601 !important;
}

@media screen and (max-width: 600px) {
	.loyaltyConnect-2B1tX {
		padding: 0;
	}

	.header-DubpV {
		padding-right: 40px;

		font-size: 24px;
	}

	.group-YXJJ2 {
		margin-top: 20px;
		grid-gap: 15px;
		gap: 15px;
	}

	.group-YXJJ2 .group__field-2a7gC {
		width: 50%;
		min-width: auto;
	}

	.group-YXJJ2 .group__field-2a7gC:not(:last-child) {
		padding-right: 0;
	}

	.group-YXJJ2 .group__field-2a7gC p {
		transform: translateY(140%);
	}

	.buttons__wrapper-36cfg {
		align-items: flex-start;
		flex-direction: column;
		grid-gap: 10px;
		gap: 10px;
	}

	div.button-3Qs5e,
	button.button-3Qs5e {
		margin-top: 20px;
		margin-left: auto;
	}

	.switch__root-1uheP {
		margin-top: 0;
		margin-bottom: 0;
	}

	.switch__label-e5HN5 {
		padding-left: 28px;
	}
}
