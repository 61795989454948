

.day-2ytNS {
	justify-content: center;
	grid-gap: 4px;
	gap: 4px;

	min-height: 96px;

	padding: 8px 0;
}

.date-1PfSc {
	padding: 0;

	color: var(--text-mid);
}

.day-2ytNS.day_selected-3h9wD {
	border-color: var(--brand-3-0);
}

.date-1PfSc,
.day_selected-3h9wD .date-1PfSc {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.day_selected-3h9wD .date-1PfSc {
	color: var(--text-base);
}

.day_notAvailable-1nnSe .date-1PfSc {
	color: var(--text-pale);
}

.date__dow-2H7xj {
	margin: 0;
	padding: 0;
}

.date__info-21bih {
	position: relative;
	top: 0;
	z-index: 1;

	max-width: 35%;

	margin: 0 auto;
}

.date__info-21bih span {
	padding: 0 4px;

	color: var(--text-pale);
	font-weight: 400;
	font-size: 13px;
}

.date__info-21bih:before {
	position: absolute;
	top: 50%;
	left: 0;
	z-index: -1;

	width: 100%;

	border-bottom: 1px dashed var(--line-separator);

	pointer-events: none;
	content: '';
}

.price__wrapper-285LH {
	flex-grow: 0;
	min-height: 32px;
}

.price-2DAoo,
.day_selected-3h9wD .price-2DAoo {
	color: var(--text-base);
	font-size: 24px;
	font-weight: 700;
	line-height: 1.3;
}

.day-2ytNS.day_best-3Obed .date-1PfSc,
.day-2ytNS.day_best-3Obed .price-2DAoo {
	color: var(--success-text);
}
