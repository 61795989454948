

.arrow_prev-2pbuy,
.arrow_next-2elLu {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 38px;
	height: 38px;

	border-radius: 50%;

	box-shadow: 0 4px 32px 7px rgba(0, 0, 0, 0.1);

	background: var(--bg-white);
}

.arrow_prev-2pbuy {
	left: -20px;
}

.arrow_next-2elLu {
	right: -20px;
}

.arrow_prev-2pbuy:after,
.arrow_next-2elLu:after {
	position: relative;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;

	width: 65%;
	height: 65%;

	margin-top: 0;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxTDEzLjE3IDEyTDguNTkgMTYuNTlMMTAgMThMMTYgMTJMMTAgNloiIGZpbGw9IiM0RDRENEQiLz4KPC9zdmc+Cg==) no-repeat center / contain;
}

.arrow_prev-2pbuy:after {
	transform: rotate(-180deg);
}
