

.id-19o2f {
	display: none;
}

.step-g8Zhv {
	position: relative;

	border: none;

	color: var(--brand-1-0);
}

.step_active-3NYKD {
	background: transparent;
}

.step_active-3NYKD:before {
	content: '';

	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;

	height: 3px;

	background: var(--brand-3-0);
}

.step-g8Zhv.step_passed-1Ngqx {
	color: var(--brand-1-0);
}

.step-g8Zhv.step_disabled-2h8rX {
	color: var(--text-light);
}

.stepbar-1ST_4 {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.05);
}

.cart-1tGGn {
	height: auto;
	margin: 4px 0;

	border-radius: 4px;
}
