.rules-1m0cO {
	padding: 40px 60px;
}

.rules-1m0cO .rules_1-1:after {
	display: none;
}

.rules__title-1UE6P {
	margin: 0;

	font-size: 20px;
	line-height: 28px;
}

.rules__text-WoUQX {
	margin: 24px 0;

	font-size: 14px;
	line-height: 21px;
}

.rules__footer-3RbZ1 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

button.rules__button-2XeZX {
	flex-shrink: 0;

	background-color: var(--common-orange);
}

button.rules__button-2XeZX:hover {
	background-color: var(--dark-orange);
}

span.rules__label-3rU1m {
	padding-right: 60px;

	font-size: 17px;
	font-family: inherit;
	line-height: 24px;
	color: var(--common-gray);
}

span.rules__label-3rU1m a {
	color: var(--text-link);
}

span.rules__checkbox-3cnI_.rules__checkbox_checked-15EPM {
	color: var(--common-blue);
}

@media screen and (max-width: 600px) {
	.rules-1m0cO {
		padding: 20px 40px 80px 20px;
	}

	.rules__footer-3RbZ1 {
		display: block;
	}

	span.rules__label-3rU1m {
		padding-right: 0;
	}

	button.rules__button-2XeZX {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		width: 100%;
		height: 60px;

		border-radius: 0;

		font-size: 17px;
	}
}
