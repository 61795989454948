.root-1rLv7 {
	width: 660px;
	padding: 40px 40px 47px;
}

.title-3buCu {
	font-size: 30px;
	font-weight: 500;
	color: var(--gray80);
}

.icon_wrapper-1FNNB {
	position: relative;
	top: 4px;

	margin-right: 10px;
}

.message-iMVM3 {
	margin-top: 50px;

	color: var(--gray80);
	font-size: 16px;
	line-height: 1.4;
}

.row-2Nzsz {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.input_wrapper-2eIOS {
	flex-grow: 1;
}

.label-3o4o3 {
	display: block;
	margin-left: 5px;
}

.error-1Gtlm {
	position: absolute;
	top: calc(100% - 7px);
	left: 36px;
}

/* stylelint-disable */
.checkbox-22EZL {
}

.checked-2rbrx {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.root-1rLv7 {
		display: flex;
		flex-direction: column;

		width: 100%;
		min-height: 100%;
		padding: 0;

		/* padding: 29px 28px; */
	}

	.title-3buCu {
		display: flex;
		flex-direction: column;
		margin-top: auto;
	}

	.message-iMVM3 {
		margin-bottom: auto;
	}

	.icon_wrapper-1FNNB {
		top: 0;

		margin-bottom: 25px;
	}

	.row-2Nzsz {
		flex-direction: column;
		align-items: normal;
	}

	.button_wrapper-2XGBI {
		margin-top: 20px;
	}
}
