

.content-lauxF {
	display: flex;
	flex-direction: column;
	padding-bottom: 65px;
	overflow-y: auto;
}

.schedule-2Sc0i {
	padding: 20px;

	border-bottom: 1px solid #e4e4e4;
}

.schedule-2Sc0i a {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	text-decoration: none;
}

.schedule-2Sc0i a > svg {
	height: 20px;
	width: 20px;
	margin-left: 4px;
}

.service-2yJ2i {
	padding: 27px 20px 20px;
}

.service-2yJ2i:not(:last-child) {
	border-bottom: 1px solid #e4e4e4;
}

.service__header-KYnGk {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.service__date-22Obn {
	font-weight: 600;
	font-size: 17px;
	line-height: 25px;
	color: #292929;
}

.service__header-KYnGk .service__tickets-3RThJ {
	display: flex;
	align-items: center;
	padding: 0 8px 0 16px;

	border-radius: 24px;

	color: #ffffff;
	font-weight: 500;
	line-height: unset;

	background: var(--common-blue);
}

.service__header-KYnGk .service__tickets-3RThJ:hover {
	background: var(--common-blue);
}

.service__tickets-3RThJ.service__tickets_selected-1ZshB {
	color: #292929;

	background: #f0f0f0;
}

.service__header-KYnGk .service__tickets_loading-2ys_F {
	padding: unset;
}

.service__tickets-3RThJ.service__tickets_selected-1ZshB:hover {
	background: #f0f0f0;
}

.service__info-2HsMk {
	display: flex;
	flex-direction: column;
}

.service__info-2HsMk > div {
	font-family: inherit;
}

.service__info-2HsMk > div svg {
	margin-right: 8px;
}

.service__info-2HsMk > div:not(:last-child) {
	margin-bottom: 12px;
}

.service__direction-2pNt1 {
	margin-bottom: 12px;
}

div.service__class-3SwBj {
	text-transform: capitalize;
}

div.service__direction-2pNt1,
div.service__class-3SwBj {
	padding: 12px 30px 12px 12px;

	border-radius: 4px;

	background: #f0f0f0;
}

.icon-1oFml {
	display: inline-block;

	height: 24px;
	margin-left: 8px;
}

.icon-1oFml.button_close-1cOqf {
	transform: rotate(45deg);

	fill: #9a9a9a;
}

.icon-1oFml.button_add-GcEVE {
	fill: #ffffff;
}

.icon-1oFml.button_download-3OdoW {
	transform: rotate(90deg) scale(0.7) translate(0, 1px);
}

.footer-Ewe8d {
	position: fixed;
	bottom: 0;

	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 11px 20px;
	height: 48px;

	background: var(--common-orange);
}

span.footer__clearButton-3lWGA,
span.footer__doneButton-253LQ {
	color: #ffffff;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;

	background-color: transparent;

	cursor: pointer;
}

.footer-Ewe8d span.footer__clearButton-3lWGA {
	font-weight: 400;
}
