.selectedFlights__total-3LTAH {
	align-items: center;
}

@media (min-width: 1024px) {
	.requestForm__order-2F9Dx {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 12px;
		gap: 12px;
	}
}
