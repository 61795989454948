

.header-2_fvJ {
	justify-content: space-between;
	grid-gap: 20px;
	gap: 20px;
}

.header_info-aJ74R {
	max-width: 75%;
}
