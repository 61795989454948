.wrapper-vuo0z {
	padding: 40px 0;

	border-top: 1px solid #b6b6b6;

	background: #ededed;
}

.insurances-2obuI {
	display: flex;
	overflow-y: auto;
}

.insurances-2obuI > div {
	margin-right: 20px;
}

.insurances-2obuI > div:first-child {
	margin-left: 40px;
}

.insurances-2obuI > div:last-child {
	margin-right: 40px;
}

.insurances-2obuI:after {
	content: '';

	min-width: 1px;
	height: 1px;
}

.header-2-X1W {
	display: flex;
	justify-content: space-between;
}

div.dialog-Xk7w4 {
	width: 100%;
	max-width: 1100px;
	overflow: visible;
}

div.dialog__header-1rI4l {
	position: relative;
}

a.rules-2V8co {
	display: flex;
	align-items: center;

	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	text-decoration: none;
}

a.rules-2V8co svg {
	padding-right: 4px;
}

.controls-H_ljI {
	padding: 20px 40px;

	display: flex;
	align-items: center;
}

.buttons-1LUnf {
	flex: 1;

	text-align: right;
}

.documents-2APzL {
	padding: 10px 20px 34px;
	margin: 0 40px;
	margin-top: 20px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

div.mobileWrapper-epOge {
	background: #f0f0f0;
}

button.dialog__button_done-2fW0N,
button.dialog__button_clear-bwlMD {
	width: 183px;
	height: 48px;

	border-radius: 2px;

	font-size: 14px;
}

button.dialog__button_clear-bwlMD {
	margin-right: 7px;

	color: #9a9a9a;

	background-color: #ededed;
}

button.dialog__button_clear-bwlMD:hover {
	background-color: #dadada;
}

@media (max-width: 1024px) {
	div.dialog-Xk7w4 {
		width: 100%;
	}

	.insurances-2obuI > div {
		margin-right: 14px;
	}

	.insurances-2obuI > div:first-child {
		margin-left: 20px;
	}

	.insurances-2obuI > div:last-child {
		margin-right: 20px;
	}
}

@media screen and (max-width: 600px) {
	.wrapper-vuo0z {
		margin-top: 20px;
		padding: 0;
		padding-bottom: 20px;

		border-top: none;

		background: none;
	}

	.documents-2APzL {
		padding: 0 25px;
	}
}
