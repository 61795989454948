.wrapper-VmxNt {
	background: var(--bg-fill);
}

.right-2YQqg {
	width: 320px;
}

button.unavailable__button-2wFGC {
	padding-top: 2px;

	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	.wrapper-VmxNt {
		background: #ffffff;
	}
}
