

.price-2UlRg {
	display: flex;
	align-items: center;

	color: var(--text-base);
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3;
}

button.button-2-6FP {
	height: 34px;

	padding: 0 16px;

	border: none;

	color: var(--white);
	font-weight: 700;
	font-size: 14px;

	background: var(--brand-1-0);

	transition: color 0.15s, background 0.15s;
}

button.button-2-6FP:hover {
	color: var(--white);

	background: var(--brand-1--1);
}

button.button-2-6FP.edit-2K98c {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);
	font-weight: 500;

	background: transparent;
}

button.button-2-6FP.edit-2K98c:hover {
	border-color: var(--brand-1-0);

	color: var(--white);

	background: var(--brand-1-0);
}
