

button.button-Rt9iZ {
	min-width: 190px;
	min-height: 48px;

	border-radius: 6px;

	font-weight: 700;

	font-size: 18px;
}

div.saveContactInfo__modal-y368a {
	padding: 32px;
}
