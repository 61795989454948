

.wrapper-3Eb2L {
	height: 64px;

	background: var(--white);
}

button.button_back-3euZf {
	width: 40px;
	height: 40px;

	border-radius: 50%;

	color: transparent;
	font-size: 0;

	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkFjdGlvbiI+CjxwYXRoIGlkPSJVbmlvbiIgZD0iTTE2LjY2NjcgOS4xNjYzNEg2LjUyNTAxTDExLjE4MzMgNC41MDgwMUwxMCAzLjMzMzAxTDMuMzMzMzQgOS45OTk2N0wxMCAxNi42NjYzTDExLjE3NSAxNS40OTEzTDYuNTI1MDEgMTAuODMzSDE2LjY2NjdWOS4xNjYzNFoiIGZpbGw9IiM4MDgwODAiLz4KPC9nPgo8L3N2Zz4K) no-repeat center;
}

button.button_continue-VymhT,
button.button_continue-VymhT:hover {
	height: 39px;
	padding: 0 24px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 14px;

	background: var(--brand-3-0);
}

button.button_back-3euZf span {
	display: none;
}
