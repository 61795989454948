.flightInfo-3gkMo {
	max-width: 16%;
}

.flightInfo__date-fp5kN {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3;
	text-transform: capitalize;
}

.flightInfo__airline-2DJgR {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.aircraft-22XOn {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.segment-XlJyz.segment_additional-1zLRl,
.totalDuration__decorator-Lc-JS {
	border-color: var(--line-separator);
}

.flightInfo__nextDay-KlIrp .flightInfo__date-fp5kN {
	color: var(--text-base);
}

.planeInfo-3oKGN {
	justify-content: center;
}

@media screen and (max-width: 600px) {
	.flightInfo__airline-2DJgR {
		font-size: 13px;
		font-weight: 400;
	}
}
