.form-1YAWA {
	padding: 64px 20px 24px;
	min-height: 196px;
}

button.button-46FJB {
	margin-right: 12px;
}

/* loading */
div.button-46FJB {
	margin-right: 12px;

	background: var(--gray50);
}

/* stylelint-disable-next-line block-no-empty */
button.button_applied-2-qr5 {
}

button.button_notApplied-1QFJ5 {
	background: var(--active30);
}

.button_notApplied-1QFJ5 svg {
	position: relative;
	top: -1px;
}

/* stylelint-disable-next-line block-no-empty */
button.button_empty-3PJKh {
}

.statusMessage-23Hdf {
	margin-top: 25px;
	display: flex;
	align-items: center;
}

.statusMessage_applied-3AuQv {
	color: var(--gray50);
}

.statusMessage_notApplied-e2bwz {
	color: var(--active);
}

.form_additionalFields-LUbGm {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

/* stylelint-disable */
.statusText-2Qidu {
}
/* stylelint-enable */

@media screen and (max-width: 600px) {
	.form-1YAWA {
		padding: 56px 20px 28px;
	}
}
