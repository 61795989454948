

.disclaimer-266eU {
	position: relative;

	padding: 12px;

	border: 1px solid var(--text-accent);
	border-radius: 4px;

	font-size: 13px;

	background: var(--bg-white);
}

.disclaimer-266eU p {
	color: var(--text-light);
}

.disclaimer-266eU p:first-of-type {
	font-weight: 700;
}

.disclaimer__root-1WYpB > span:first-of-type {
	display: none;
}
